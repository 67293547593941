// src/store/index.js
import { createStore } from 'vuex';

export default createStore({
  state: {
    isLoading: false
  },
  mutations: {
    SET_LOADING(state, payload) {
      state.isLoading = payload;
    }
  },
  actions: {
    setLoading({ commit }, isLoading) {
      commit('SET_LOADING', isLoading);
    }
  },
  getters: {
    isLoading: state => state.isLoading
  }
});
