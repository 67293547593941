<template>
  <div class="md:flex md:items-center md:justify-between">
    <div class="min-w-0 flex-1">
      <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">{{ podcast.title }}</h2>
      <!-- <p class="mt-2 max-w-4xl text-sm text-gray-500">{{ podcast.description }}</p> -->
    </div>
    <div class="mt-4 flex md:ml-4 md:mt-0">
      <!-- RSS Feed URL Display -->
      <input v-if="podcast.rss_feed" type="text" readonly :value="correctedRssFeedUrl" class="rounded-l-md border border-r-0 border-gray-300 px-3 py-2 text-sm shadow-sm focus:ring-indigo-500 focus:border-indigo-500">

      <!-- Copy RSS Link Button -->
      <button v-if="podcast.rss_feed" @click="copyRssLink" type="button" class="group inline-flex items-center rounded-r-md border border-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-50">
        <ClipboardIcon class="mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
        RSS
        <span class="ml-2 inline-block rounded-full bg-green-100 px-2 py-1 text-xs font-semibold text-green-800" v-show="copySuccess">Copied!</span>
      </button>

      <!-- New Project Button -->
      <button type="button" @click="navigateToCreateEpisode" class="ml-3 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
        + New episode
      </button>

    </div>
  </div>

</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import axios from 'axios';
import { useRoute, useRouter } from 'vue-router';
import { ClipboardIcon } from '@heroicons/vue/24/outline';

const podcast = ref({});
const route = useRoute();
const router = useRouter();
const seriesId = computed(() => route.params.seriesId);
const copySuccess = ref(false);

const correctedRssFeedUrl = computed(() => {
  return podcast.value.rss_feed 
    ? (podcast.value.rss_feed.includes('/api') 
        ? podcast.value.rss_feed 
        : podcast.value.rss_feed.replace('/rss/', '/api/rss/'))
    : '';
});

onMounted(async () => {
  if (!seriesId.value) {
    console.error("Series ID is undefined.");
    return;
  }

  try {
    const response = await axios.get(`/api/podcasts/${seriesId.value}`);
    podcast.value = response.data;
  } catch (error) {
    console.error("Error fetching podcast details: ", error);
  }
});

const copyRssLink = () => {
  if (correctedRssFeedUrl.value) {
    navigator.clipboard.writeText(correctedRssFeedUrl.value);
    copySuccess.value = true;
    setTimeout(() => copySuccess.value = false, 2000); // Reset after 2 seconds
  }
};

const navigateToCreateEpisode = () => {
  router.push({ name: 'CreateEpisode', params: { seriesId: seriesId.value }});
};
</script>

