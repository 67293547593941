<template>
  <TransitionRoot as="template" :show="open" class="z-50">
    <Dialog as="div" class="relative z-10" @close="open = false">
      <div class="fixed inset-0" />
      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
              <DialogPanel class="pointer-events-auto w-screen max-w-md">
                <form @submit.prevent="publishEpisode" class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                  <div class="h-0 flex-1 overflow-y-auto">
                    <div class="bg-indigo-700 px-4 py-6 sm:px-6">
                        <div class="flex items-center justify-between">
                            <DialogTitle class="text-base font-semibold leading-6 text-white">Publish Your Podcast</DialogTitle>
                            <div class="ml-3 flex h-7 items-center">
                                <button type="button" class="relative rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white" @click="open = false">
                                    <span class="absolute -inset-2.5" />
                                    <span class="sr-only">Close panel</span>
                                    <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                                </button>
                            </div>
                        </div>
                        <div class="mt-1">
                            <p class="text-sm text-indigo-300">Publish to a podcast series, or create a new one!</p>
                        </div>
                    </div>

                    <div class="flex flex-1 flex-col justify-between">
                      <div class="divide-y divide-gray-200 px-4 sm:px-6">
                        <div class="space-y-6 pb-5 pt-6">

                          <!-- Episode Name Input -->
                          <div>
                            <label for="episode-name" class="block text-sm font-medium leading-6 text-gray-900">Episode Name</label>
                            <div class="mt-2">
                              <input type="text" v-model="episodeName" name="episode-name" id="episode-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Enter episode name" />
                            </div>
                          </div>

                          <!-- Podcast Series Dropdown -->
                          <Listbox as="div" v-model="selectedSeries">
                            <ListboxLabel class="block text-sm font-medium leading-6 text-gray-900">Select Your Podcast Series</ListboxLabel>
                            <div class="relative mt-2">
                              <ListboxButton class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                <span class="block truncate">{{ selectedSeries.name }}</span>
                                <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                  <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </span>
                              </ListboxButton>

                              <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                                <ListboxOptions class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                  <ListboxOption as="template" v-for="series in seriesList" :key="series.id" :value="series" v-slot="{ active, selected }">
                                    <li :class="[active ? 'bg-indigo-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-8 pr-4']">
                                      <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{ series.name }}</span>

                                      <span v-if="selected" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 left-0 flex items-center pl-1.5']">
                                        <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                      </span>
                                    </li>
                                  </ListboxOption>
                                </ListboxOptions>
                              </transition>
                            </div>
                          </Listbox>

                          <!-- New Series Input -->
                          <div v-if="selectedSeries.id === 'new'">
                            <label for="new-series-name" class="block text-sm font-medium leading-6 text-gray-900">New Series Name</label>
                            <div class="mt-2">
                              <input type="text" name="new-series-name" id="new-series-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Enter new series name" />
                            </div>
                          </div>

                          <!-- Other form inputs can be added below as needed -->
                          <!-- Example: Episode Description, Episode Tags, etc. -->

                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-shrink-0 justify-end px-4 py-4">
                    <button type="button" class="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" @click="open = false">Cancel</button>
                    <button type="submit" class="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Publish</button>
                  </div>
                </form>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
<!-- Notification Section -->
<div aria-live="assertive" class="pointer-events-none fixed inset-0 z-50 flex items-end px-4 py-6 sm:items-start sm:p-6" v-if="showNotification">
  <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
    <transition enter-active-class="transform ease-out duration-300 transition"
                enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
                leave-active-class="transition ease-in duration-100"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0">
      <div class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
        <div class="p-4">
          <div class="flex items-start">
            <div class="flex-shrink-0">
              <CheckCircleIcon class="h-6 w-6 text-green-400" aria-hidden="true" />
            </div>
            <div class="ml-3 w-0 flex-1 pt-0.5">
              <p class="text-sm font-medium text-gray-900">Episode Published</p>
              <p class="mt-1 text-sm text-gray-500">
                Your episode has been successfully published. 
                <a :href="episodeLink" class="text-indigo-600 hover:text-indigo-800" target="_blank">View Episode</a>
              </p>
            </div>
            <div class="ml-4 flex flex-shrink-0">
              <button type="button" @click="showNotification = false" class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                <span class="sr-only">Close</span>
                <XMarkIcon class="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</div>


</template>

<script setup>
import axios from 'axios';
import { ref, onMounted, defineProps } from 'vue';
import { useStore } from 'vuex';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot, Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue';
import { CheckCircleIcon, XMarkIcon } from '@heroicons/vue/24/outline';

const store = useStore();
const props = defineProps({
    projectId: {
        type: Number,
        required: true
    }
});
const open = ref(true);
const showNotification = ref(false);
const episodeName = ref('');
const seriesList = ref([]);
const selectedSeries = ref({});
const episodeLink = ref(''); // Ref for storing the episode link

const fetchPodcastSeries = async () => {
    try {
        const response = await axios.get('/api/podcasts');
        seriesList.value = response.data.map(series => ({ id: series.id, name: series.title }));
        selectedSeries.value = seriesList.value[0] || {};
    } catch (error) {
        console.error("Error fetching podcast series:", error);
    }
};

const publishEpisode = async () => {
    open.value = false; // Close the slideover immediately
    store.dispatch('setLoading', true);
    try {
        const response = await axios.post('/api/publish_episode', {
            project_id: props.projectId,
            podcast_id: selectedSeries.value.id,
            title: episodeName.value
            // Include other data fields as necessary
        });

        if (response.data && response.data.episode_id) {
            const episodeId = response.data.episode_id;  // Using the key as defined in Flask route
            episodeLink.value = `/studio/podcasts/${selectedSeries.value.id}/${episodeId}`;
            showNotification.value = true; // Show success notification
        } else {
            console.error("Episode ID not received in response");
            // Handle case where episode ID is not received
        }
    } catch (error) {
        console.error("Error during episode publishing:", error);
        open.value = true; // Re-open the slideover in case of an error
    } finally {
        store.dispatch('setLoading', false);
    }
};



onMounted(fetchPodcastSeries);
</script>


