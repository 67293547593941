<template>
  <div :style="recordingInterfaceStyle" class="recording-interface flex flex-col w-full bg-white overflow-hidden">

      <!-- Section 1: Time and Selection Controls -->
      <div class="workspace-section flex flex-wrap justify-between items-center py-4 px-6 bg-gray-900 text-white">
        <div class="flex items-center space-x-4 w-1/2">
          <div class="text-3xl font-semibold text-white">{{ currentTime || '00:00' }}</div>
          <div class="flex flex-col items-start text-lg">
            <div>Total: {{ totalTime || '00:00' }}</div>
            <div>Hover: {{ hoverTime || '00:00' }}</div>
          </div>
        </div>
        <div class="flex flex-wrap items-center space-x-4 w-1/2 justify-end">
          <div class="flex space-x-3 items-center">
            <span>Start: {{ startTime || '00:00' }}</span>
            <span>End: {{ endTime || '00:00' }}</span>
            <span>Duration: {{ duration || '00:00' }}</span>
          </div>
        <button @click="clearSelection" type="button" class="inline-flex items-center gap-x-1 rounded-md bg-red-500 px-2 py-1.5 text-xs font-semibold text-white shadow-sm hover:bg-red-600 transition duration-150">
          Clear
        </button>

        </div>
      </div>



    <!-- Section 2: Waveform Display -->
    <div class="workspace-section flex-grow flex flex-col bg-white relative"> <!-- outer section -->
      <!-- Time Indicators (inner section) -->
      <div class="px-4 py-2 bg-gray-750 w-full">
        <div id="wave-timeline" class="w-full relative"></div>
      </div>
      <!-- Vertical Time Indicator spanning entire section -->
      <!-- <div id="customTimeIndicator" class="absolute left-0 top-0 bottom-0 w-0.5 bg-orange-500 z-10"></div>  -->
      <!-- Waveform (inner section) -->
      <div class="flex-grow px-4 bg-gray-750 flex items-center justify-center relative">
          <!-- Waveform boundaries -->
          <div class="absolute left-0 right-0 top-4 h-0.5 bg-gray"></div>
          <div class="absolute left-0 right-0 bottom-4 h-0.5 bg-gray"></div>
          <div id="waveform" class="w-full"></div>
      </div>
      <!-- Zoom Controls -->
      <div class="absolute left-4 bottom-4 flex flex-col space-y-2">
          <label class="text-gray text-xs">Zoom:</label>
          <input type="range" min="10" max="1000" v-model="zoomLevel" class="w-40"/>
      </div>
    </div>

    <!-- Section 3: The Bottom Bar -->
    <div class="workspace-section px-4 py-1 bg-gray border-t border-gray-300">
      <div class="flex justify-between items-center">
        <!-- Left Side: Record, Import, Generate icons -->
        <div class="flex space-x-3 items-center">
          <!-- <button type="button" class="inline-flex items-center gap-x-1 rounded-md bg-pink-500 px-2 py-1.5 text-xs font-semibold text-white shadow-sm hover:bg-pink-400">
            <MicrophoneIcon class="h-4 w-4" aria-hidden="true"></MicrophoneIcon>
            Record
          </button> -->
          <button type="button" @click="$emit('generate')" class="inline-flex items-center gap-x-1 rounded-md bg-yellow-500 px-2 py-1.5 text-xs font-semibold text-white shadow-sm hover:bg-yellow-400">
            <LightBulbIcon class="h-4 w-4" aria-hidden="true"></LightBulbIcon>
            Generate
          </button>
        </div>

      <!-- Center: Playback Controls with Enhanced Time Indicators -->
      <div class="flex space-x-4 items-center justify-center flex-grow">
        <div class="flex space-x-4 items-center">

          <button @click="goBack" class="rounded-full p-2 hover:bg-gray-200">
            <BackwardIcon class="h-5 w-5 text-gray-600"></BackwardIcon>
          </button>
          <button @click="playPause" class="rounded-full p-3 hover:bg-gray-200">
            <PlayIcon v-if="!isPlaying" class="h-6 w-6 text-gray-600"></PlayIcon>
            <PauseIcon v-else class="h-6 w-6 text-gray-600"></PauseIcon>
          </button>
          <button @click="goForward" class="rounded-full p-2 hover:bg-gray-200">
            <ForwardIcon class="h-5 w-5 text-gray-600"></ForwardIcon>
          </button>
          <!-- <button @click="stop" class="rounded-full p-2 hover:bg-gray-200">
            <StopIcon class="h-5 w-5 text-gray-600"></StopIcon>
          </button> -->
          <!-- <button @click="record" class="rounded-full p-2 hover:bg-gray-200">
            <MicrophoneIcon class="h-5 w-5 text-gray-600"></MicrophoneIcon>
          </button> -->
        </div>
      </div>



        <!-- Right Side: Volume Control, Publish, and Export -->
        <div class="flex space-x-3 items-center">
        <div class="relative">
          <button @click="showVolumeSlider = !showVolumeSlider" class="rounded-full p-1 hover:bg-gray-200">
            <SpeakerWaveIcon class="h-4 w-4 text-gray-600"></SpeakerWaveIcon>
          </button>
          <div v-if="showVolumeSlider" class="absolute bottom-full left-1/2 transform -translate-x-1/2 w-8 bg-white">
            <input type="range" v-model="currentVolume" orient="vertical" class="w-full h-24" style="writing-mode: bt-lr; -webkit-appearance: slider-vertical;">
          </div>
        </div>
        <button type="button" @click="$emit('import')" class="inline-flex items-center gap-x-1 rounded-md bg-indigo-600 px-2 py-1.5 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500">
            <ArrowUpOnSquareIcon class="h-4 w-4" aria-hidden="true"></ArrowUpOnSquareIcon>
            Import
          </button>
          <button type="button" @click="$emit('publish')" class="inline-flex items-center gap-x-1 rounded-md bg-indigo-600 px-2 py-1.5 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500">
            <ArrowRightCircleIcon class="h-4 w-4" aria-hidden="true"></ArrowRightCircleIcon>
            Publish
          </button>
          <button type="button" @click="$emit('export')" class="inline-flex items-center gap-x-1 rounded-md bg-indigo-600 px-2 py-1.5 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500">
            <PaperAirplaneIcon class="h-4 w-4" aria-hidden="true"></PaperAirplaneIcon>
            Export
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WaveSurfer from 'wavesurfer.js';
import RegionsPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions.min.js';
import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline.min.js';

import {
  ArrowUpOnSquareIcon,
  LightBulbIcon,
  SpeakerWaveIcon,
  ArrowRightCircleIcon,
  BackwardIcon,
  PlayIcon,
  PauseIcon,
  ForwardIcon,
  PaperAirplaneIcon,
} from '@heroicons/vue/24/outline';

export default {
  name: 'ProjectPageRecordingInterface',
  props: {
    projectId: Number, // Assuming projectId is passed as a prop
    audioUrl: String,
    // peaksData: Array,
  },
  data() {
    return {
      wavesurfer: null,
      isPlaying: false,
      currentVolume: 50,
      showVolumeSlider: false,
      zoomLevel: 100,
      currentTime: '00:00',
      totalTime: '00:00',
      hoverTime: '00:00',
      startTime: '00:00',
      endTime: '00:00',
      duration: '00:00',
      activeRegion: null,
      customTimeIndicator: null,
      isRecording: false,
      recognition: null,
      transcript: '',
      lastLoadedRange: null,
    };
  },
  watch: {
    zoomLevel(value) {
      if (this.wavesurfer) {
        this.wavesurfer.zoom(value);
      }
    },
    currentVolume(newVolume) {
      if (this.wavesurfer) {
        this.wavesurfer.setVolume(newVolume / 100);
      }
    },
    audioUrl(newUrl) {
      if (newUrl && this.wavesurfer) {
        this.loadAudioSegment(0, 100000); // Load initial segment
      }
    },
  },
  computed: {
    recordingInterfaceStyle() {
      const header = document.querySelector('.sticky.top-0.z-40');
      const headerHeight = header ? header.offsetHeight : 0;
      const calculatedHeight = `calc(100vh - ${headerHeight}px)`;
      return {
        height: calculatedHeight,
        position: 'absolute',
        top: '0',
        left: '0'
      };
    }
  },
  methods: {
    formatTime(seconds) {
      const minutes = Math.floor(seconds / 60);
      seconds = seconds % 60;
      return `${minutes.toString().padStart(2, '0')}:${seconds.toFixed(0).padStart(2, '0')}`;
    },
    clearSelection() {
      if (this.activeRegion) {
        this.activeRegion.remove();
        this.activeRegion = null;
        this.startTime = '00:00';
        this.endTime = '00:00';
        this.duration = '00:00';
      }
    },
    initializeWaveform() {
      this.wavesurfer = WaveSurfer.create({
        container: '#waveform',
        waveColor: 'rgb(236, 72, 153)',
        cursorColor: 'orange',
        progressColor: 'rgb(186, 22, 103)',
        height: 200,
        maxMdb: -30,
        minPxPerSec: 10,
        backend: 'MediaElement',  // Change to WebAudio backend
        barHeight: 3,
        plugins: [
          RegionsPlugin.create(),
          TimelinePlugin.create({
            container: '#wave-timeline',
            notchPercentHeight: 90,
            unlabeledNotchColor: 'black',
            primaryColor: 'black',
            secondaryColor: 'black',
            primaryFontColor: 'black',
            secondaryFontColor: 'black',
          })
        ]
      });

      const waveformContainer = document.querySelector('#waveform');
      waveformContainer.addEventListener('mousemove', (e) => {
        const rect = waveformContainer.getBoundingClientRect();
        const mouseX = e.clientX - rect.left;
        const duration = this.wavesurfer.getDuration();
        const width = this.wavesurfer.drawer.getWidth();
        const time = (mouseX / width) * duration;
        this.hoverTime = this.formatTime(time);
      });

      this.wavesurfer.on('ready', () => {
        this.totalTime = this.formatTime(this.wavesurfer.getDuration());
        this.wavesurfer.enableDragSelection({ color: 'rgba(79, 70, 53, 0.5)' });
        this.wavesurfer.setVolume(this.currentVolume / 100);
        this.loadingProgress = 100; // Full waveform loaded
      });

      this.wavesurfer.on('region-created', region => {
        region.color = 'rgba(79, 70, 229, 0.5)';
        region.on('click', () => {
          region.play();
        });
        region.on('out', () => {
          if (this.activeRegion === region) {
            this.activeRegion = null;
          }
        });
        region.on('update-end', () => {
          this.startTime = this.formatTime(region.start);
          this.endTime = this.formatTime(region.end);
          this.duration = this.formatTime(region.end - region.start);
        });
        this.activeRegion = region;
        this.startTime = this.formatTime(region.start);
        this.endTime = this.formatTime(region.end);
        this.duration = this.formatTime(region.end - region.start);
      });

      this.wavesurfer.on('region-removed', () => {
        this.startTime = '00:00';
        this.endTime = '00:00';
        this.duration = '00:00';
      });

      this.wavesurfer.on('audioprocess', (time) => {
        this.currentTime = this.formatTime(time);
        // Check if we need to load more of the audio
        const duration = this.wavesurfer.getDuration();
        if (duration - time < 30 && !this.lastLoadedRange) { // 30 seconds threshold
          this.loadMoreAudio();
        }
      });

      // Load the initial segment
      if (this.audioUrl) {
        this.loadAudioSegment(0, 10000); // Load the first 100kB
      }
    },

    loadAudioSegment(startByte, endByte) {
    // Consistently request a small initial chunk
    const initialChunkSize = 100000; // 100kB
    if (!this.lastLoadedRange) {
        endByte = initialChunkSize;
    }

    this.lastLoadedRange = `bytes=${startByte}-${endByte}`;
    console.log(`Requesting range: ${this.lastLoadedRange}`);

    this.wavesurfer.load(
        `/api/projects/${this.projectId}/serve_audio`,
        null,
        'auto',
        {
            method: 'GET',
            headers: { 'Range': this.lastLoadedRange },
            responseType: 'blob',
        }
    );

    // Update loading progress
    if (this.totalFileSize) {
        this.loadingProgress = Math.min((endByte / this.totalFileSize) * 100, 100);
    }
    console.log(`Loading progress: ${this.loadingProgress}%`);

  },

    loadMoreAudio() {
        const startEndMatch = this.lastLoadedRange.match(/(\d+)-(\d+)/);
        if (startEndMatch) {
            const [, , end] = startEndMatch;
            const newStart = parseInt(end) + 1;
            const newEnd = newStart + 100000; // Load the next 100kB
            console.log(`Loading more audio: new range ${newStart}-${newEnd}`);
            this.loadAudioSegment(newStart, newEnd);
        }
    },

    onWaveformReady() {
      console.log('Waveform is ready');
      console.log(`Total waveform duration: ${this.wavesurfer.getDuration()} seconds`);
      // Additional logic to execute when waveform is ready, if needed
    },


    playPause() {
      this.wavesurfer.playPause();
      this.isPlaying = !this.isPlaying;
    },
    goForward() {
      this.wavesurfer.skip(5);
    },
    goBack() {
      this.wavesurfer.skip(-5);
    },
    stop() {
      this.wavesurfer.stop();
      this.isPlaying = false;
    },
    toggleVolumeSlider() {
      this.showVolumeSlider = !this.showVolumeSlider;
    },
    setVolume() {
      this.wavesurfer.setVolume(this.currentVolume / 100);
    },
  },
  mounted() {
    this.initializeWaveform();
    this.wavesurfer.on('ready', this.onWaveformReady);
  },
  beforeUnmount() {
    if (this.wavesurfer) {
      this.wavesurfer.destroy();
    }
  },
  components: {
    ArrowUpOnSquareIcon,
    LightBulbIcon,
    SpeakerWaveIcon,
    ArrowRightCircleIcon,
    BackwardIcon,
    PlayIcon,
    PauseIcon,
    ForwardIcon,
    PaperAirplaneIcon
  }
}
</script>
