<template>
  <div class="flex min-h-full flex-1 items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
    <div class="w-full max-w-sm space-y-10">
      <div>
        <img class="mx-auto h-10 w-auto" src="@/assets/logo-main.png" alt="Your Company" />
        <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Reset Password</h2>
      </div>
      <!-- Add @submit.prevent directive here -->
      <form class="space-y-6" @submit.prevent="submitForm">
        <div>
          <label for="new-password" class="sr-only">New Password</label>
          <input 
            id="new-password" 
            type="password" 
            required 
            class="relative block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" 
            placeholder="New Password"
            v-model="newPassword"
          />
        </div>
        <div>
          <label for="confirm-password" class="sr-only">Confirm Password</label>
          <input 
            id="confirm-password" 
            type="password" 
            required 
            class="relative block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" 
            placeholder="Confirm Password"
            v-model="confirmPassword"
          />
        </div>
        <div>
          <button type="submit" class="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Reset Password</button>
        </div>
      </form>
      <p v-if="message" class="text-center text-sm text-red-600">{{ message }}</p> <!-- Display message -->
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      newPassword: '',
      confirmPassword: '',
      token: '',
      message: '',
    };
  },
  methods: {
    async submitForm() {
      if (this.newPassword !== this.confirmPassword) {
        this.message = 'Passwords do not match.';
        return;
      }

      try {
        await axios.post('/api/reset-password', {
          token: this.token,
          password: this.newPassword,
        });
        this.message = 'Your password has been reset successfully.';
        setTimeout(() => {
          this.$router.push('/signin');
        }, 2000);
      } catch (error) {
        if (error.response && error.response.data) {
          // Displaying the specific error message from the backend
          this.message = error.response.data.message || 'Failed to reset your password. Please try again.';
        } else {
          this.message = 'Failed to reset your password. Please try again.';
        }
      }
    },

  },
  mounted() {
    this.token = this.$route.query.token;
  },
};
</script>

