<template>
  <div id="zoom-component">
    <!-- Zoom Meeting will be attached here -->
    <div id="zmmtg-root"></div>
  </div>
</template>

<script>

export default {
  name: "ZoomMeeting",
  };
</script>

<style scoped>
/* Add your styles here if needed */
</style>
