<template>
  <StudioLayout>
    <div v-if="isLoading">
      <LoadingSpinner />
    </div>
    <div v-else-if="projects && projects.length" class="overflow-hidden rounded-lg bg-white shadow">
      <div class="px-4 py-5 sm:p-6">
        <!-- Project Title Filter Integration -->
        <div class="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
          <div class="mt-3 sm:ml-4 sm:mt-0">
            <label for="mobile-search-candidate" class="sr-only">Search</label>
            <label for="desktop-search-candidate" class="sr-only">Search</label>
            <div class="flex rounded-md shadow-sm">
              <div class="relative flex-grow focus-within:z-10">
                <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <MagnifyingGlassIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
                <input type="text" name="mobile-search-candidate" id="mobile-search-candidate"
                       class="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:hidden"
                       placeholder="Search" />
                <input type="text" name="desktop-search-candidate" id="desktop-search-candidate" v-model="searchQuery"       
                class="hidden w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 text-sm leading-6 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:block"
                       placeholder="Search Projects" />
              </div>
              <button type="button"
                      class="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                <BarsArrowUpIcon class="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                Sort
                <ChevronDownIcon class="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
              </button>
            </div>
          </div>
          <button @click="createNewProject" type="button"
                  class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            + New project
          </button>
        </div>

        <!-- Projects List Integration -->
        <ul role="list" class="divide-y divide-gray-100" v-if="filteredProjects.length">
          <li v-for="project in filteredProjects" :key="project.id" class="flex items-center justify-between gap-x-6 py-5">
            <div class="min-w-0 flex-1">
              <div class="flex items-start gap-x-3">
                <div v-if="project.editing">
                  <input type="text" v-model="project.editTitle"
                         @keydown.enter="saveProjectTitle(project)"
                         @blur="saveProjectTitle(project)"
                         class="text-sm font-semibold leading-6 text-gray-900 bg-transparent border border-blue-500 p-1 outline-none"
                         autofocus>
                </div>
                <div v-else @dblclick="renameProject(project)" class="cursor-pointer">
                  <p class="text-sm font-semibold leading-6 text-gray-900">{{ project.title }}</p>
                </div>
              </div>
              <div class="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                <p class="whitespace-nowrap">
                  Last updated <time :datetime="project.last_updated">{{ new Date(project.last_updated).toLocaleDateString() }}</time>
                </p>
                <p class="truncate">Created by {{ project.createdBy }}</p>
              </div>
            </div>
            <div class="flex flex-none items-center gap-x-4">
              <a :href="`/studio/projects/${project.id}`" class="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">View project</a>
              <Menu as="div" class="relative flex-none">
                <MenuButton class="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                  <span class="sr-only">Open options</span>
                  <EllipsisVerticalIcon class="h-5 w-5" aria-hidden="true" />
                </MenuButton>
                <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                  <MenuItems class="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                    <MenuItem v-slot="{ active }">
                      <a @click="renameProject(project)" :class="[active ? 'bg-gray-50' : '', 'block px-3 py-1 text-sm leading-6 text-gray-900']">
                        Rename<span class="sr-only">, {{ project.title }}</span>
                      </a>
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                      <a @click="deleteProject(project)" :class="[active ? 'bg-gray-50' : '', 'block px-3 py-1 text-sm leading-6 text-gray-900']">
                        Delete<span class="sr-only">, {{ project.title }}</span>
                      </a>
                    </MenuItem>
                  </MenuItems>
                </transition>
              </Menu>
            </div>
          </li>
        </ul>
        <!-- No Results Found -->
        <div v-if="projects.length > 0 && filteredProjects.length === 0" class="mt-4">
          <p class="text-center text-lg text-gray-600">
            No results found for "{{ searchQuery }}"
          </p>
        </div>


      </div>
    </div>
    <div v-else class="overflow-hidden rounded-lg bg-white shadow">
      <div class="px-4 py-5 sm:p-6">
        <div class="text-center">
          <svg class="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path vector-effect="non-scaling-stroke" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z" />
          </svg>
          <h3 class="mt-2 text-sm font-semibold text-gray-900">No projects</h3>
          <p class="mt-1 text-sm text-gray-500">Get started by creating a new project.</p>
          <div class="mt-6">
            <button @click="createNewProject" type="button" class="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              + New project
            </button>
          </div>
        </div>
      </div>
    </div>
  </StudioLayout>
</template>

<script>
import StudioLayout from '@/components/StudioLayout.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import axios from 'axios';
import { ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { EllipsisVerticalIcon, MagnifyingGlassIcon, BarsArrowUpIcon, ChevronDownIcon } from '@heroicons/vue/20/solid';

export default {
  components: {
    StudioLayout,
    LoadingSpinner,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    EllipsisVerticalIcon,
    MagnifyingGlassIcon,
    BarsArrowUpIcon,
    ChevronDownIcon
  },
  setup() {
    const store = useStore();
    const projects = ref([]);
    const router = useRouter();
    const searchQuery = ref('');


    onMounted(async () => {
      store.dispatch('setLoading', true);
      try {
        const response = await axios.get('/api/projects');
        projects.value = response.data.map(project => ({
          ...project,
          editing: false,
          editTitle: project.title
        }));
      } catch (error) {
        console.error("Error fetching projects: ", error);
      } finally {
        store.dispatch('setLoading', false);
      }
    });

    // Computed property for filtering projects based on the search query
    const filteredProjects = computed(() => {
      if (!searchQuery.value) return projects.value;
      return projects.value.filter(project =>
        project.title.toLowerCase().includes(searchQuery.value.toLowerCase())
      );
    });

    const isLoading = computed(() => store.getters.isLoading);

    const createNewProject = async () => {
      // Logic for creating a new project
      try {
        const response = await axios.post('/api/projects', {});
        if (response.data && response.data.project_id) {
          router.push(`/studio/projects/${response.data.project_id}`);
        } else {
          console.error("Project ID not received");
        }
      } catch (error) {
        console.error("Error creating new project: ", error);
      }
    };

    const renameProject = (project) => {
      // Logic for renaming a project
      project.editing = true;
      project.editTitle = project.title;
    };

    const saveProjectTitle = async (project) => {
      // Logic for saving a project title
      const newTitle = project.editTitle.trim();
      if (newTitle && newTitle !== project.title) {
        try {
          await axios.put(`/api/projects/${project.id}`, { title: newTitle });
          project.title = newTitle; // Update local state with the new title
        } catch (error) {
          console.error("Error saving project title: ", error);
        }
      }
      project.editing = false;
    };

    const deleteProject = async (project) => {
      // Logic for deleting a project
      try {
        await axios.delete(`/api/projects/${project.id}`);
        projects.value = projects.value.filter(p => p.id !== project.id);
      } catch (error) {
        console.error("Error deleting project: ", error);
      }
    };

    return { projects, isLoading, createNewProject, renameProject, saveProjectTitle, deleteProject, searchQuery, filteredProjects };
  }
}
</script>


