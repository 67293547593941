<template>
  <StudioLayout>
    <!-- Specific content for UserDashboard goes here -->
  </StudioLayout>
</template>

<script>
import StudioLayout from '../components/StudioLayout.vue';

export default {
  name: 'UserDashboard',
  components: {
    StudioLayout
  }
}
</script>
