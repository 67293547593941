
<template>
  <div>
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog as="div" class="relative z-50 lg:hidden" @close="sidebarOpen = false">
        <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-900/80" />
        </TransitionChild>

        <div class="fixed inset-0 flex">
          <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
            <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
              <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
                <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
                  <button type="button" class="-m-2.5 p-2.5" @click="sidebarOpen = false">
                    <span class="sr-only">Close sidebar</span>
                    <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              <!-- Sidebar component, swap this element with another sidebar if you like -->
              <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4 ring-1 ring-white/10">
                <div class="flex h-16 shrink-0 items-center">
                  <img class="h-8 w-auto" src="@/assets/favicon-white.png" alt="Your Company" />
                </div>
                <nav class="flex flex-1 flex-col">
                  <ul role="list" class="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul role="list" class="-mx-2 space-y-1">
                        <li v-for="item in navigation" :key="item.name">
                          <a :href="item.href" :class="[item.current ? 'bg-gray-800 text-white' : 'text-gray-400 hover:text-white hover:bg-gray-800', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']">
                            <component :is="item.icon" class="h-6 w-6 shrink-0" aria-hidden="true" />
                            {{ item.name }}
                          </a>
                        </li>
                      </ul>
                    </li>
                    <!-- <li>
                    <div class="text-xs font-semibold leading-6 text-gray-400">Your podcasts</div>
                      <ul role="list" class="-mx-2 mt-2 space-y-1">
                        <li v-for="team in teams" :key="team.name">
                          <a :href="team.href" :class="[team.current ? 'bg-gray-800 text-white' : 'text-gray-400 hover:text-white hover:bg-gray-800', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']">
                            <span class="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">{{ team.initial }}</span>
                            <span class="truncate">{{ team.name }}</span>
                          </a>
                        </li>
                      </ul>
                    </li> -->
                    <li class="mt-auto">
                      <a href="/studio/profile" :class="[isSettingsActive ? 'bg-gray-800 text-white' : 'text-gray-400 hover:text-white hover:bg-gray-800', 'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6']">
                          <Cog6ToothIcon class="h-6 w-6 shrink-0" aria-hidden="true" />
                          Settings
                      </a>

                    </li>
                  </ul>
                </nav>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>

  <!-- Static sidebar for desktop -->
  <div :class="collapsed ? 'hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-20 lg:flex-col' : 'hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col'">
      <!-- Sidebar component -->
      <div class="flex flex-grow flex-col overflow-y-auto bg-gray-900 px-6 pb-4">
        <div class="flex h-16 shrink-0 items-center justify-between">
          <img class="h-8 w-auto" src="@/assets/favicon-white.png" alt="Your Company" />
              <!-- button 
              <button @click="toggleCollapse" class="text-gray-400 hover:text-white focus:outline-none">
                  <Bars3Icon v-if="!collapsed" class="h-6 w-6" />
                  <XMarkIcon v-else class="h-6 w-6" />
              </button>
              -->

          </div>
          <nav class="flex flex-1 flex-col">
    <div class="flex-grow">
        <ul role="list" class="flex flex-col gap-y-7">
            <li>
                <ul role="list" class="-mx-2 space-y-1">
                    <li v-for="item in navigation" :key="item.name">
                        <a :href="item.href" :class="[item.current ? 'bg-gray-800 text-white' : 'text-gray-400 hover:text-white hover:bg-gray-800', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']">
                            <component :is="item.icon" class="h-6 w-6 shrink-0" aria-hidden="true" />
                            <span v-if="!collapsed">{{ item.name }}</span>
                        </a>
                    </li>
                </ul>
            </li>
            <!-- Uncommented podcasts section -->
            <!--
            <li>
                <div v-if="!collapsed" class="text-xs font-semibold leading-6 text-gray-400">Your podcasts</div>
                <ul role="list" class="-mx-2 mt-2 space-y-1">
                    <li v-for="team in teams" :key="team.name">
                        <a :href="team.href" :class="[team.current ? 'bg-gray-800 text-white' : 'text-gray-400 hover:text-white hover:bg-gray-800', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']">
                            <span class="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">{{ team.initial }}</span>
                            <span v-if="!collapsed" class="truncate">{{ team.name }}</span>
                        </a>
                    </li>
                </ul>
            </li>
            -->
        </ul>
    </div>
    <ul role="list" class="mt-auto">
        <li>
          <a href="/studio/profile" :class="[isSettingsActive ? 'bg-gray-800 text-white' : 'text-gray-400 hover:text-white hover:bg-gray-800', 'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6']">
              <Cog6ToothIcon class="h-6 w-6 shrink-0" aria-hidden="true" />
              Settings
          </a>

        </li>
    </ul>
</nav>


      </div>
  </div>


    <div class="lg:pl-72">

      <div class="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
        <button type="button" class="-m-2.5 p-2.5 text-gray-700 lg:hidden" @click="sidebarOpen = true">
          <span class="sr-only">Open sidebar</span>
          <Bars3Icon class="h-6 w-6" aria-hidden="true" />
        </button>

        <!-- Separator -->
        <div class="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true" />

        <div class="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">

          <nav class="flex relative flex flex-1" aria-label="Breadcrumb">
            <ol role="list" class="flex items-center space-x-4">
              <li>
                <div>
                  <a href="/studio/podcasts" class="text-gray-400 hover:text-gray-500">
                    <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                    <span class="sr-only">Home</span>
                  </a>
                </div>
              </li>
              <!-- Breadcrumb items -->
              <li v-for="(page, index) in pages" :key="index">
                  <div class="flex items-center">
                      <ChevronRightIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                      <a :href="page.href" class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700" :aria-current="page.current ? 'page' : undefined">{{ page.name }}</a>
                  </div>
              </li>
            </ol>
          </nav>



          <div class="flex items-center gap-x-4 lg:gap-x-6">
            <!-- <button type="button" class="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500">
              <span class="sr-only">View notifications</span>
              <BellIcon class="h-6 w-6" aria-hidden="true" />
            </button> -->

            <!-- Separator -->
            <div class="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10" aria-hidden="true" />

        <!-- Profile dropdown -->
        <Menu as="div" class="relative">
            <MenuButton class="-m-1.5 flex items-center p-1.5">
                <span class="sr-only">Open user menu</span>
                <span class="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-white text-gray-800 text-sm font-medium">{{ username.charAt(0).toUpperCase() }}</span>
                <span class="hidden lg:flex lg:items-center">
                    <span class="ml-4 text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">{{ username }}</span>
                    <ChevronDownIcon class="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
            </MenuButton>
              <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                <MenuItems class="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                  <MenuItem v-for="item in userNavigation" :key="item.name" v-slot="{ active }">
                    <a :href="item.href" :class="[active ? 'bg-gray-50' : '', 'block px-3 py-1 text-sm leading-6 text-gray-900']" @click="item.onClick">
      {{ item.name }}
    </a>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>

        </div>
      </div>


      <main class="py-10">
        <div class="px-4 sm:px-6 lg:px-8">
          <!-- Your content -->
              <!-- Slot to accept content from parent views -->
          <slot></slot>
        </div>
      </main>
    </div>
  </div>
</template>


<script>
import axios from 'axios';
import {
    Dialog,
    DialogPanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
} from '@headlessui/vue';
import {
    Bars3Icon,
    BellIcon,
    Cog6ToothIcon,
    MicrophoneIcon,
    FolderIcon,
    BookOpenIcon,
    XMarkIcon,
    ChevronDownIcon,
    ChevronRightIcon,
    UserCircleIcon,
} from '@heroicons/vue/24/outline';

import {
    HomeIcon,
} from '@heroicons/vue/20/solid';

export default {
    name: 'StudioLayout',
    data() {
        return {
            sidebarOpen: false,
            collapsed: false,
            username: '', // Username will be set via API call
            navigation: [
                { name: 'Podcasts', href: '/studio/podcasts', icon: MicrophoneIcon, current: this.$route.path === '/studio/podcasts' },
                { name: 'Projects', href: '/studio/projects', icon: FolderIcon, current: this.$route.path === '/studio/projects' },
                { name: 'Docs', href: 'https://docs.poddy.ai/', icon: BookOpenIcon, current: this.$route.path === '/studio/projects' },
                // ... other navigation items
            ],
            teams: [
                { id: 1, name: 'Rest is History', href: '/studio/podcasts/1', initial: 'R', current: false },
                { id: 2, name: 'True crime with Kevin Fendergast', href: '/studio/podcasts/2', initial: 'T', current: false },
                { id: 3, name: 'Orbital Exchange', href: '/studio/podcasts/3', initial: 'O', current: false },
                // ... other team data
            ],
            userNavigation: [
                { name: 'Support', href: 'mailto:support@poddy.ai' },
                { name: 'Sign out', href: '#', onClick: this.logout },
                // ... other user navigation data
            ],
            pages: [],
        };
    },
    methods: {


async updateBreadcrumbs() {
    const pathArray = this.$route.path.split('/').filter(path => path);
    let breadcrumbPages = [
        { name: 'Studio', href: '', current: false },
    ];

    // Add 'Podcasts' only if in the podcasts section
    if (pathArray.includes('podcasts')) {
        breadcrumbPages.push({ name: 'Podcasts', href: '/studio/podcasts', current: false });

        for (let i = 1; i < pathArray.length; i++) {
            const segment = pathArray[i];

            if (!isNaN(segment)) {
                const podcastId = segment;
                const podcastTitle = await this.fetchPodcastTitle(podcastId);
                breadcrumbPages.push({ name: podcastTitle, href: `/studio/podcasts/${podcastId}`, current: false });

                const episodeId = pathArray[i + 1];
                if (episodeId && !isNaN(episodeId)) {
                    const episodeTitle = await this.fetchEpisodeTitle(episodeId);
                    breadcrumbPages.push({ name: episodeTitle, href: `/studio/podcasts/${podcastId}/${episodeId}`, current: true });
                    break; // Stop loop after adding episode
                }
            }
        }
    } else {
        // For other routes like 'projects' or 'profile'
        const segment = pathArray[1]; // Assuming these routes are at the same level as 'podcasts'
        const breadcrumbLabel = segment.charAt(0).toUpperCase() + segment.slice(1);
        breadcrumbPages.push({ name: breadcrumbLabel, href: `/studio/${segment}`, current: true });
    }

    // Set the last item as the current breadcrumb
    if (breadcrumbPages.length > 1) {
        breadcrumbPages[breadcrumbPages.length - 1].current = true;
    } else if (breadcrumbPages.length === 1) {
        breadcrumbPages[0].current = true;
    }

    this.pages = breadcrumbPages;
},



        toggleSidebar() {
            this.sidebarOpen = !this.sidebarOpen;
        },

        toggleCollapse() {
            this.collapsed = !this.collapsed;
        },

        setInitialActiveState() {
            this.navigation.forEach(item => {
                item.current = item.href === this.$route.path;
            });
            this.teams.forEach(team => {
                team.current = team.href === this.$route.path;
            });
        },

        convertToReadableLabel(segment) {
            const labels = {
                'studio': 'Studio',
                'podcasts': 'Podcasts',
                'projects': 'Projects',
                // ... other static segments
            };

            if (labels[segment]) {
                return labels[segment];
            }

            if (!isNaN(segment)) {
                if (this.$route.path.includes('/podcasts/')) {
                    return 'Series ' + segment;
                }
                // ... other conditions for numeric segments
            }

            return segment.charAt(0).toUpperCase() + segment.slice(1);
        },

        async fetchPodcastTitle(podcastId) {
            try {
                const response = await axios.get(`/api/podcasts/${podcastId}`);
                return response.data.title;
            } catch (error) {
                console.error('Error fetching podcast title:', error);
                return 'Unknown Podcast';
            }
        },

        async fetchEpisodeTitle(episodeId) {
            try {
                const response = await axios.get(`/api/episodes/${episodeId}`);
                return response.data.title;
            } catch (error) {
                console.error('Error fetching episode title:', error);
                return 'Unknown Episode';
            }
        },

        async fetchUserData() {
            try {
                const response = await axios.get('/api/user');
                this.username = response.data.username;
            } catch (error) {
                console.error("Error fetching user data:", error);
                this.username = 'Guest';
            }
        },

        logout() {
            localStorage.removeItem('userToken');
            this.$router.push('/signin');
        },
    },

    watch: {
        async '$route'() {
            await this.updateBreadcrumbs();
            this.setInitialActiveState();
            this.fetchUserData();
        }
    },

    mounted() {
        this.updateBreadcrumbs();
        this.setInitialActiveState();
        this.fetchUserData();
    },

    computed: {
        isSettingsActive() {
            return this.$route.path === '/studio/profile';
        }
    },

    components: {
        Dialog,
        DialogPanel,
        Menu,
        MenuButton,
        MenuItem,
        MenuItems,
        TransitionChild,
        TransitionRoot,
        Bars3Icon,
        BellIcon,
        Cog6ToothIcon,
        FolderIcon,
        BookOpenIcon,
        HomeIcon,
        MicrophoneIcon,
        XMarkIcon,
        ChevronDownIcon,
        ChevronRightIcon,
        UserCircleIcon
    }
}
</script>



