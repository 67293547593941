<template>
  <StudioLayout>
  <div class="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">

    <div class="px-4 py-5 sm:p-6">
      <CreateSeriesForm /> 
    </div>

  </div>
    </StudioLayout>
</template>

<script>
import StudioLayout from '@/components/StudioLayout.vue';
import CreateSeriesForm from '@/components/CreateSeriesForm.vue';

export default {
  components: {
    StudioLayout,
    CreateSeriesForm
  }
}
</script>