<template>
  <ProjectPageStudioLayout>
    <div v-if="isLoading">
      <LoadingSpinner />
    </div>
    <ImportAudioSlideover 
        :projectId="projectId" 
        @uploadStarted="handleUploadStart" 
        @fileUploaded="handleFileUploaded" 
        v-if="activeSlideover === 'import'" 
        @close="activeSlideover = null" 
      />

    <PublishSlideover 
      :projectId="projectId" 
      v-if="activeSlideover === 'publish'" 
      @close="activeSlideover = null" 
    />
    <ExportSlideover 
      v-if="activeSlideover === 'export'"
      :open="activeSlideover === 'export'"
      :projectId="projectId"
      @close="activeSlideover = null" 
    />

    <GenerateSlideover :projectId="projectId" v-if="activeSlideover === 'generate'" @audioGenerated="fetchProjectDetails" @close="activeSlideover = null" />


    <ProjectPageRecordingInterface 
      :audio-url="audioUrl"
      :project-id="projectId"
      @import="activeSlideover = 'import'" 
      @publish="activeSlideover = 'publish'" 
      @export="activeSlideover = 'export'" 
      @generate="activeSlideover = 'generate'"
    />
  </ProjectPageStudioLayout>
</template>

<script>
import ProjectPageStudioLayout from '@/components/ProjectPageStudioLayout.vue';
import ProjectPageRecordingInterface from '@/components/ProjectPageRecordingInterface.vue';
import ImportAudioSlideover from '@/components/ImportAudioSlideover.vue';
import PublishSlideover from '@/components/PublishSlideover.vue';
import ExportSlideover from '@/components/ExportSlideover.vue';
import GenerateSlideover from '@/components/GenerateSlideover.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue'; // Ensure this path is correct
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    ProjectPageStudioLayout,
    ProjectPageRecordingInterface,
    ImportAudioSlideover,
    PublishSlideover,
    ExportSlideover,
    GenerateSlideover,
    LoadingSpinner,
  },
  computed: {
    ...mapGetters(['isLoading']), // Add a getter for the global loading state
  },
  data() {
    return {
      isNewProject: true,
      projectId: this.$route.params.projectId ? parseInt(this.$route.params.projectId) : null,
      audioUrl: null,
      activeSlideover: null,
    };
  },
  methods: {
    ...mapActions(['setLoading']), // Map Vuex actions
    handleContinue() {
      this.isNewProject = false;
    },
    async fetchProjectDetails() {
      this.setLoading(true); // Set global loading state
      try {
        const response = await axios.get(`/api/projects/${this.projectId}`);
        if (response.data) {
          this.audioUrl = response.data.audio_url;
        }
      } catch (error) {
        console.error("Error fetching project details:", error);
      } finally {
        this.setLoading(false); // Reset global loading state
      }
    },
    handleAudioGenerated() {
      this.fetchProjectDetails();
    },
    handleUploadStart() {
      this.setLoading(true); // Set global loading state
    },
    handleFileUploaded() {
      this.setLoading(false); // Reset global loading state
      this.fetchProjectDetails();
    }
  },
  mounted() {
    if (this.projectId) {
      this.fetchProjectDetails();
    }
  }
}
</script>



