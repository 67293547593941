<template>
  <div class="loading-spinner">
    <!-- Spinner -->
    <div class="spinner">
      <!-- Logo inside the Spinner -->
      <img class="logo" src="@/assets/favicon-blue.png" alt="Poddy.ai" />
    </div>
  </div>
</template>

<style scoped>
  .loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 150px); /* Subtract header height */
    position: fixed; 
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000; /* High z-index to ensure it's on top */
  }

  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 50px; /* Adjust size as needed */
    height: 50px; /* Adjust size as needed */
    border-radius: 50%;
    border-left-color: #4F46E5; /* Your brand color */
    position: relative; /* Needed to position the logo correctly */
    animation: spin 1s ease infinite;
  }

  .spinner .logo {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 70%; /* Adjust size as needed */
    transform: translate(-50%, -50%);
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>
