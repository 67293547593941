<template>
  <StudioLayout>
    <!-- Loading State -->
    <div v-if="isLoading">
      <LoadingSpinner />
    </div>

    <!-- Search Bar and Podcast List -->
    <div v-else class="overflow-hidden rounded-lg bg-white shadow">
      <div class="px-4 py-5 sm:p-6">
        <!-- Podcast Title Filter Integration -->
        <div v-if="podcasts.length > 0" class="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
          <div class="mt-3 sm:ml-4 sm:mt-0">
            <label for="mobile-search-candidate" class="sr-only">Search</label>
            <label for="desktop-search-candidate" class="sr-only">Search</label>
            <div class="flex rounded-md shadow-sm">
              <div class="relative flex-grow focus-within:z-10">
                <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <MagnifyingGlassIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
                <!-- Desktop Search Input -->
                <input type="text" name="desktop-search-candidate" id="desktop-search-candidate"
                      class="hidden w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 text-sm leading-6 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:block"
                      placeholder="Search Series" 
                      @input="searchQuery = $event.target.value">
                <!-- Mobile Search Input -->
                <input type="text" name="mobile-search-candidate" id="mobile-search-candidate" 
                      class="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:hidden"
                      placeholder="Search"
                      @input="searchQuery = $event.target.value">
              </div>
              <button type="button" class="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                <BarsArrowUpIcon class="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                Sort
                <ChevronDownIcon class="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
              </button>
            </div>
          </div>
          <a href="/studio/podcasts/create-series">
            <button type="button" 
                    class="ml-auto rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              + New series
            </button>
          </a>
        </div>

        <!-- Display "No Results Found" if search yields no results -->
        <div v-if="filteredPodcasts.length === 0 && searchQuery" class="mt-4">
          <p class="text-center text-lg text-gray-600">No results found for "{{ searchQuery }}"</p>
        </div>

        <!-- Podcast List Integration -->
        <div v-if="filteredPodcasts.length">
          <ul role="list" class="divide-y divide-gray-100">
            <li v-for="podcast in filteredPodcasts" :key="podcast.id" class="flex items-center justify-between gap-x-6 py-5">
              <div class="min-w-0">
                <div class="flex items-start gap-x-3">
                  <p class="text-sm font-semibold leading-6 text-gray-900">{{ podcast.title }}</p>
                  <p class="rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset">{{ podcast.episodeCount }} episodes</p>
                </div>
                <div class="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                  <p class="whitespace-nowrap">
                    Last updated <time :datetime="podcast.last_updated">{{ new Date(podcast.last_updated).toLocaleDateString() }}</time>
                  </p>
                </div>
              </div>
              <div class="flex flex-none items-center gap-x-4">
                <a :href="`/studio/podcasts/${podcast.id}`" class="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block">View series<span class="sr-only">, {{ podcast.title }}</span></a>
                <!-- Menu with additional actions -->
                <Menu as="div" class="relative flex-none">
                  <MenuButton class="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                    <span class="sr-only">Open options</span>
                    <EllipsisVerticalIcon class="h-5 w-5" aria-hidden="true" />
                  </MenuButton>
                  <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                    <MenuItems class="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      <MenuItem v-slot="{ active }">
                        <a @click="deletePodcast(podcast)" :class="[active ? 'bg-gray-50' : '', 'block px-3 py-1 text-sm leading-6 text-gray-900']">
                          Delete<span class="sr-only">, {{ podcast.title }}</span>
                        </a>
                      </MenuItem>
                    </MenuItems>
                  </transition>
                </Menu>
              </div>
            </li>
          </ul>
        </div>

        <!-- Empty State (no podcasts at all) -->
        <div v-if="!podcasts.length">
          <div class="text-center">
            <svg class="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path vector-effect="non-scaling-stroke" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z" />
            </svg>
            <h3 class="mt-2 text-sm font-semibold text-gray-900">No podcasts</h3>
            <p class="mt-1 text-sm text-gray-500">Get started by creating a new podcast.</p>
            <div class="mt-6">
              <a href="/studio/podcasts/create-series">
                <button type="button" class="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                  <PlusIcon class="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                  New series
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </StudioLayout>
</template>

<script>
import StudioLayout from '@/components/StudioLayout.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import axios from 'axios';
import { ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';

import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { MagnifyingGlassIcon, BarsArrowUpIcon, ChevronDownIcon, EllipsisVerticalIcon, PlusIcon } from '@heroicons/vue/20/solid';

export default {
  components: {
    StudioLayout,
    LoadingSpinner,
    MagnifyingGlassIcon,
    BarsArrowUpIcon,
    ChevronDownIcon,
    EllipsisVerticalIcon, 
    Menu, 
    MenuButton, 
    MenuItem, 
    MenuItems, 
    PlusIcon
  },
  setup() {
    const store = useStore();
    const podcasts = ref([]);
    const searchQuery = ref('');

    const filteredPodcasts = computed(() => {
      if (searchQuery.value) {
        return podcasts.value.filter(podcast =>
          podcast.title.toLowerCase().includes(searchQuery.value.toLowerCase())
        );
      }
      return podcasts.value;
    });

    // Fetch podcasts data on mounted
    onMounted(async () => {
      store.dispatch('setLoading', true);
      try {
        const response = await axios.get('/api/podcasts');
        podcasts.value = response.data.map(podcast => ({
          ...podcast,
          episodeCount: podcast.episode_count, // Include episode count
          // other properties if needed
        }));
      } catch (error) {
        console.error("Error fetching podcasts: ", error);
      } finally {
        store.dispatch('setLoading', false);
      }
    });

        // Delete podcast function
      const deletePodcast = async (podcast) => {
      try {
        await axios.delete(`/api/podcasts/${podcast.id}`);
        podcasts.value = podcasts.value.filter(p => p.id !== podcast.id);
      } catch (error) {
        console.error("Error deleting podcast: ", error);
      }
    };

    return { podcasts, isLoading: computed(() => store.getters.isLoading), filteredPodcasts, searchQuery, deletePodcast };
  }
}
</script>
