import { createRouter, createWebHistory } from 'vue-router';

// Importing the views using '@' alias for consistency
import MainLanding from '@/views/MainLanding.vue';
import UserDashboard from '@/views/UserDashboard.vue';
import ProjectsMain from '@/views/ProjectsMain.vue';
import PodcastsMain from '@/views/PodcastsMain.vue';
import PodcastSeries from '@/views/PodcastSeries.vue';
import CreatePodcastSeries from '@/views/CreatePodcastSeries.vue';
import PodcastEpisode from '@/views/PodcastEpisode.vue';
import ProjectPage from '@/views/ProjectPage.vue';
import UserProfile from '@/views/UserProfile.vue';
import SupportPage from '@/views/SupportPage.vue';
import SignUp from '@/views/SignUp.vue';
import RegisterSuccess from '@/views/RegisterSuccess.vue';
import SignIn from '@/views/SignIn.vue';
import ForgotPassword from '@/views/ForgotPassword.vue';
import ResetPassword from '@/views/ResetPassword.vue';
import VerifyEmail from '@/views/VerifyEmail.vue';
import LiveRecordingStudio from '@/views/LiveRecordingStudio.vue';
import PodcastPlayerView from '@/views/PodcastPlayerView.vue';
import CreateEpisodePage from '@/views/CreateEpisodePage.vue';

const routes = [
    {
      path: '/',
      name: 'MainLanding',
      component: MainLanding
    },
    {
      path: '/studio/dashboard',
      name: 'UserDashboard',
      component: UserDashboard
    },
    {
      path: '/studio/projects',
      name: 'projectsMain',
      component: ProjectsMain
    },
    {
      path: '/studio/podcasts',
      name: 'podcastsMain',
      component: PodcastsMain
    },
    {
      path: '/studio/podcasts/:seriesId',
      name: 'podcastSeries',
      component: PodcastSeries
    },
    {
      path: '/studio/podcasts/create-series',
      name: 'CreatePodcastSeries',
      component: CreatePodcastSeries
    },
    {
      path: '/studio/live-recording/:projectId', 
      name: 'liveRecordingStudio',
      component: LiveRecordingStudio 
    },
    {
      path: '/studio/podcasts/:seriesId/:episodeId',
      name: 'podcastEpisode',
      component: PodcastEpisode
    },
    {
      path: '/studio/projects/:projectId',
      name: 'projectPage',
      component: ProjectPage
    },
    {
      path: '/studio/profile',
      name: 'userProfile',
      component: UserProfile
    },
    {
      path: '/support',
      name: 'support',
      component: SupportPage
    },
    {
      path: '/signup',
      name: 'signup',
      component: SignUp
    },
    {
      path: '/register/success',
      name: 'RegisterSuccess',
      component: RegisterSuccess, // Component to handle post-Stripe registration logic
      // Add meta field to bypass the auth check if needed
      meta: { bypassAuth: true }
    },
    {
      path: '/signin',
      name: 'signin',
      component: SignIn
    },
    {
      path: '/forgot-password',
      name: 'forgotPassword',
      component: ForgotPassword
    },
    {
      path: '/reset-password',
      name: 'resetPassword',
      component: ResetPassword
    },
    {
      path: '/verify-email',
      name: 'verifyEmail',
      component: VerifyEmail,
      props: (route) => ({ token: route.query.token, error: route.query.error })
    },
    {
      path: '/player/:projectId',
      name: 'PodcastPlayer',
      component: PodcastPlayerView,
    },  
    {
      path: '/studio/podcasts/:seriesId/create-episode',
      name: 'CreateEpisode',
      component: CreateEpisodePage,
      props: true, // This enables the route params to be passed as props to the component
    },
    
    // {
    //   path: '/:pathMatch(.*)*',
    //   name: 'NotFound',
    //   beforeEnter: (to) => {
    //     // Check if the path starts with '/rss/'
    //     if (to.path.startsWith('/rss/')) {
    //       // Redirect to the Flask server
    //       // window.location.href = `https://poddy-v2.onrender.com${to.fullPath}`;
    //       // window.location.href = `http://localhost:5000${to.fullPath}`;
    //       window.location.href = `${window.location.origin}/api/rss${to.fullPath.substring(4)}`;

    //       // window.location.href = `${backendBaseUrl}${to.fullPath}`;
    //     } else {
    //       // For any other non-matched route, you can redirect, show a 404 page, etc.
    //       // Example: next({ name: 'MainLanding' });
    //       // Or simply show a 404 not found component
    //     }
    //   }
    // }
    {
      path: '/:pathMatch(.*)*',
      name: 'NotFound',
      beforeEnter: (to, from, next) => {
        if (to.path.startsWith('/rss/')) {
          window.location.href = `${window.location.origin}/api/rss${to.fullPath.substring(4)}`;
        } else {
          next(); // Correctly call next here
        }
      }
    }
    
    
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});


router.beforeEach((to, from, next) => {
  const publicPages = ['/signup', '/signin', '/forgot-password', '/reset-password', '/verify-email', '/'];
  const authRequired = !publicPages.includes(to.path) && !to.path.startsWith('/player/');
  const loggedIn = localStorage.getItem('userToken');

  // Check for Stripe session ID in the URL
  const queryParams = new URLSearchParams(window.location.search);
  const hasSessionId = queryParams.has('session_id');

  // Check if the route is the register success route
  const isRegisterSuccessRoute = to.path === '/register/success';

  if (authRequired && !loggedIn && !hasSessionId && !isRegisterSuccessRoute) {
    next('/signin');
  } else {
    next();
  }
});



export default router;
