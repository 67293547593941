<template>
  <StudioLayout>
    <!-- Loading State -->
    <div v-if="isLoading">
      <LoadingSpinner />
    </div>

    <!-- Data State (episodes available) -->
    <div v-else-if="episodes && episodes.length" class="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
      <div class="px-4 py-5 sm:px-6">
        <SeriesDescription />
        <!-- Tabs -->
        <div class="border-b border-gray-200 mt-4">
          <nav class="flex space-x-8" aria-label="Tabs">
            <button
              @click="currentTab = 'episodes'"
              :class="[
                'whitespace-nowrap py-4 px-1 font-medium text-sm',
                currentTab === 'episodes' ? 'text-indigo-600 border-indigo-500 border-b-2' : 'text-gray-500 hover:text-gray-700'
              ]"
            >
              Episodes
            </button>
            <button
              @click="currentTab = 'settings'"
              :class="[
                'whitespace-nowrap py-4 px-1 font-medium text-sm',
                currentTab === 'settings' ? 'text-indigo-600 border-indigo-500 border-b-2' : 'text-gray-500 hover:text-gray-700'
              ]"
            >
              Settings
            </button>
          </nav>
        </div>
      </div>

      <div class="px-4 py-5 sm:p-6 w-full">
        <div v-if="currentTab === 'episodes'">
          <EpisodeList :podcast-id="podcastId" />
        </div>
        <div v-if="currentTab === 'settings'" class="w-full">
          <!-- Settings Form -->
          <form @submit.prevent="updatePodcast" class="w-full">
            <div class="space-y-8">
              <div>
                <label for="seriesName" class="block text-sm font-medium leading-6 text-gray-900">Series Name</label>
                <input type="text" v-model="podcast.title" id="seriesName" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
              </div>

              <div>
                <label for="seriesDescription" class="block text-sm font-medium leading-6 text-gray-900">Series Description</label>
                <textarea v-model="podcast.description" id="seriesDescription" rows="3" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"></textarea>
              </div>

              <!-- Category Dropdown -->
              <!-- <div v-if="categories.length > 0 && podcast.category"> 
            <label for="seriesCategory" class="block text-sm font-medium leading-6 text-gray-900">Series Category</label>
            <select v-model="podcast.category" id="seriesCategory" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
              <option v-for="category in categories" :key="category.id" :value="category.name">{{ category.name }}</option>
            </select>
            </div> -->

              <div>
                <label for="coverPhoto" class="block text-sm font-medium leading-6 text-gray-900">Series Cover Photo</label>
                <input type="file" id="coverPhoto" @change="handleCoverPhotoUpload" class="mt-1 block w-full rounded-md border-gray-300 text-sm text-gray-900 file:border-0 file:bg-indigo-50 file:text-indigo-700 file:cursor-pointer file:px-4 file:py-2 file:rounded-md file:shadow-sm hover:file:bg-indigo-100" />
                <!-- Display current cover photo if available -->
                <div v-if="podcast.coverPhoto" class="mt-4">
                  <img :src="podcast.coverPhoto" alt="Current Cover" class="h-32 w-32 object-cover rounded-md">
                </div>
              </div>

              <div class="flex justify-end">
                <button type="submit" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Update Series</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- Empty State (no episodes) -->
    <div v-else class="overflow-hidden rounded-lg bg-white shadow">
      <div class="px-4 py-5 sm:p-6">
        <div class="text-center">
          <svg class="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path vector-effect="non-scaling-stroke" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z" />
          </svg>
          <h3 class="mt-2 text-sm font-semibold text-gray-900">No episodes</h3>
          <p class="mt-1 text-sm text-gray-500">Get started by creating a new project that can then be published as an episode.</p>
          <div class="mt-6">
            <button @click="navigateToCreateEpisode" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              + New episode
            </button>

          </div>
        </div>
      </div>
    </div>
  </StudioLayout>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import axios from 'axios';
import StudioLayout from '@/components/StudioLayout.vue';
import EpisodeList from '@/components/EpisodeList.vue';
import SeriesDescription from '@/components/SeriesDescription.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';

export default {
  components: {
    StudioLayout,
    EpisodeList,
    SeriesDescription,
    LoadingSpinner,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const podcastId = ref(Number(route.params.seriesId));
    const episodes = ref([]);
    const isLoading = computed(() => store.getters.isLoading);
    const currentTab = ref('episodes');
    const podcast = ref(null); // Initialize as null to ensure reactivity
    const coverPhoto = ref(null);

    // Categories list (this could also be fetched from an API)
    const categories = ref([
    { id: 1, name: 'Arts and Entertainment' },
    { id: 2, name: 'Business and Technology' },
    { id: 3, name: 'Comedy' },
    { id: 4, name: 'Educational' },
    { id: 5, name: 'Games' },
    { id: 6, name: 'Kids and Family' },
    { id: 7, name: 'Lifestyle and Health' },
    { id: 8, name: 'Music' },
    { id: 9, name: 'News and Politics' },
    { id: 10, name: 'Society and Culture' },
    { id: 11, name: 'Sports and Recreation' },
    { id: 12, name: 'Stories' },
    { id: 13, name: 'True Crime' },
      // Add more categories as needed
    ]);

    onMounted(async () => {
      store.dispatch('setLoading', true);
      try {
        const response = await axios.get(`/api/podcasts/${podcastId.value}`);
        podcast.value = response.data;

        // console.log("Podcast category from API:", podcast.value.category); // Debugging line
        // console.log("Available categories:", categories.value.map(c => c.name)); // Debugging line

        // if (!categories.value.some(category => category.name === podcast.value.category)) {
        //   console.error("Podcast category does not match available categories");
        // }

        episodes.value = await axios.get(`/api/podcasts/${podcastId.value}/episodes`).then(res => res.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        store.dispatch('setLoading', false);
      }
    });

    const handleCoverPhotoUpload = event => {
      coverPhoto.value = event.target.files[0];
    };

    // const createNewProject = async () => {
    //   try {
    //     const response = await axios.post('/api/projects', {});
    //     if (response.data && response.data.project_id) {
    //       router.push(`/studio/projects/${response.data.project_id}`);
    //     } else {
    //       console.error("Project ID not received");
    //     }
    //   } catch (error) {
    //     console.error("Error creating new project: ", error);
    //   }
    // };

    const updatePodcast = async () => {
      const formData = new FormData();
      formData.append('title', podcast.value.title);
      formData.append('description', podcast.value.description);
      // formData.append('category', podcast.value.category); // Use the category from the podcast object

      if (coverPhoto.value) {
        formData.append('coverPhoto', coverPhoto.value);
      }

      try {
        await axios.put(`/api/podcasts/${podcastId.value}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log("Podcast series updated successfully");
        

        // Refresh the podcast data to reflect the update
        const updatedResponse = await axios.get(`/api/podcasts/${podcastId.value}`);
        podcast.value = updatedResponse.data;
        coverPhoto.value = null; // Reset cover photo file after successful update
        router.go();
      } catch (error) {
        console.error('Error updating podcast:', error);
      }
    };

    return {
      podcastId,
      episodes,
      isLoading,
      currentTab,
      podcast,
      updatePodcast,
      handleCoverPhotoUpload,
      categories,
      // createNewProject,
    };
  },
  methods: {
  navigateToCreateEpisode() {
    this.$router.push({ name: 'CreateEpisode', params: { podcastId: this.podcastId }});
  }
  },
};
</script>