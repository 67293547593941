<template>
  <div class="flex min-h-full flex-1 items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
    <div class="w-full max-w-sm space-y-10">
      <div>
        <img class="mx-auto h-10 w-auto" src="@/assets/logo-main.png" alt="Your Company" />
        <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Verify Email</h2>
      </div>
      <div class="space-y-6">
        <p v-if="verificationMessage" class="text-center text-sm leading-6 text-green-600">{{ verificationMessage }}</p>
        <p class="text-center text-sm leading-6 text-gray-500">Please click the button below to verify your email address.</p>
        <div>
          <button type="button" class="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" @click="verifyEmail">Verify Email</button>
        </div>
      </div>
      <p class="text-center text-sm leading-6 text-gray-500">
        Didn't receive an email?
        {{ ' ' }}
        <a href="#" class="font-semibold text-indigo-600 hover:text-indigo-500" @click="resendVerification">Resend Verification</a>
      </p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      verificationMessage: ''
    };
  },
  methods: {
    async verifyEmail() {
      const token = this.$route.query.token;
      if (!token) {
        this.verificationMessage = 'No verification token found.';
        return;
      }

      try {
        const response = await axios.get(`api/verify_email?token=${token}`);
        this.verificationMessage = response.data.message;
        // Redirect to sign-in page after a short delay to show the verification message
        setTimeout(() => {
          this.$router.push('/signin');
        }, 3000); // 2 seconds delay
      } catch (error) {
        this.verificationMessage = (error.response && error.response.data && error.response.data.message) 
                                  ? error.response.data.message 
                                  : 'Verification failed. Please try again.';
      }
    },
    async resendVerification() {
      const token = this.$route.query.token;
      if (!token) {
        this.verificationMessage = 'No verification token found for resending email.';
        return;
      }

      try {
        const response = await axios.post('api/resend-verification', { token: token });
        if (response.status === 200) {
          this.verificationMessage = "A new verification email has been sent.";
        }
      } catch (error) {
        this.verificationMessage = 'Failed to resend verification email. Please try again later.';
      }
    }

  }
};
</script>
