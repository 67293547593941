<template>
  <StudioLayout>
    <div class="max-w-4xl mx-auto px-4 py-5 sm:p-6 bg-white shadow sm:rounded-lg">
      <div class="space-y-6">
        <div class="flex justify-between items-center">
            <h2 class="text-base font-semibold leading-7 text-gray-900">Create new project or import existing</h2>
            <!-- <p class="mt-1 text-sm leading-6 text-gray-600">Please enter the details of your episode here.</p> -->
          <div class="flex gap-4">
            <button @click="createNewProject" type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              + Create new project
            </button>
            <button @click="showImportForm = !showImportForm" type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <!-- <ArrowUpOnSquareIcon class="h-4 w-4" aria-hidden="true"></ArrowUpOnSquareIcon> -->
                Import existing
            </button>
          </div>
        </div>

        <form v-if="showImportForm" @submit.prevent="submitEpisode">
          <div class="grid grid-cols-3 gap-6">
            <div class="col-span-3 sm:col-span-2">
              <label for="episode-title" class="block text-sm font-medium text-gray-700">Episode Title</label>
              <input type="text" name="episode-title" id="episode-title" v-model="episodeTitle" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
            </div>

            <div class="col-span-full">
                <label for="audio-file" class="block text-sm font-medium leading-6 text-gray-900">Audio File</label>
                <div class="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                <div class="text-center">
                  <PhotoIcon class="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                  <div class="mt-4 flex text-sm leading-6 text-gray-600">
                    <label for="file-upload" class="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500">
                      <span>{{ audioFile ? audioFile.name : 'Upload a file' }}</span>
                      <input id="file-upload" name="file-upload" type="file" class="sr-only" @change="handleFileChange" />
                    </label>
                    <p class="pl-1">or drag and drop</p>
                  </div>
                  <p class="text-xs leading-5 text-gray-600">MP3, WAV, AAC up to 10MB</p>
                </div>
              </div>

            </div>
          </div>

          <div class="mt-6 flex justify-end">
            <button type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Upload
            </button>
          </div>
        </form>

                <!-- Loading State -->
                <div v-if="isLoading" class="flex flex-col items-center justify-center">
          <LoadingSpinner /> <!-- Display the loading spinner -->
          <!-- <p class="text-sm font-semibold mt-2">Uploading...</p> Display the uploading message -->
        </div>

      </div>
    </div>
  </StudioLayout>
</template>

<script>
import StudioLayout from '@/components/StudioLayout.vue';
import axios from 'axios';
import { PhotoIcon } from '@heroicons/vue/24/solid';
import LoadingSpinner from '@/components/LoadingSpinner.vue'; // Import the LoadingSpinner component

export default {
  components: {
    StudioLayout,
    PhotoIcon,
    LoadingSpinner, // Register the LoadingSpinner component
  },
  data() {
    return {
      showImportForm: false,
      episodeTitle: '',
      audioFile: null,
      isLoading: false, // Data property to track the loading state
    };
  },
  methods: {
    createNewProject() {
      axios.post('/api/projects', {})
        .then(response => {
          if (response.data && response.data.project_id) {
            this.$router.push(`/studio/projects/${response.data.project_id}`);
          }
        })
        .catch(error => {
          console.error("Error creating new project:", error);
        });
    },
    handleFileChange(event) {
      this.audioFile = event.target.files[0];
    },
    submitEpisode() {
      if (!this.episodeTitle || !this.audioFile) {
        alert("Both title and audio file are required.");
        return;
      }

      this.isLoading = true; // Set isLoading to true when the upload starts

      const formData = new FormData();
      formData.append('title', this.episodeTitle);
      formData.append('audioFile', this.audioFile);

      axios.post(`/api/podcasts/${this.$route.params.seriesId}/episodes`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(() => {
        this.isLoading = false; // Set isLoading to false when the upload is complete
        this.$router.push(`/studio/podcasts/${this.$route.params.seriesId}`);
      })
      .catch(error => {
        this.isLoading = false; // Ensure isLoading is set to false even if there's an error
        console.error("Error submitting episode:", error);
      });
    },
  },
}
</script>

