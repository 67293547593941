<template>
  <div class="flex min-h-full flex-1 items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
    <div class="w-full max-w-sm space-y-10">
      <div>
        <img class="mx-auto h-10 w-auto" src="@/assets/logo-main.png" alt="Your Company" />
        <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Forgot Password</h2>
      </div>
      <!-- Add @submit.prevent directive here -->
      <form class="space-y-6" @submit.prevent="submitForm">
        <div>
          <label for="email-address" class="sr-only">Email address</label>
          <input 
            id="email-address" 
            name="email" 
            type="email" 
            autocomplete="email" 
            required 
            class="relative block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" 
            placeholder="Email address"
            v-model="email"  
          />
        </div>
        <div>
          <button type="submit" class="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Send Reset Link</button>
        </div>
      </form>
      <p v-if="message" class="text-center text-sm text-red-600">{{ message }}</p> <!-- Display message -->
      <p class="text-center text-sm leading-6 text-gray-500">
        Remembered your password?
        {{ ' ' }}
        <a href="/signin" class="font-semibold text-indigo-600 hover:text-indigo-500">Log in here</a>
      </p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      email: '',
      message: '',
    };
  },
  methods: {
    async submitForm() {
      if (!this.email) {
        this.message = 'Please enter your email.';
        return;
      }

      try {
        const response = await axios.post('/api/forgot-password', { email: this.email });
        this.message = response.data.message;
      } catch (error) {
        this.message = (error.response && error.response.data && error.response.data.message) 
                       ? error.response.data.message 
                       : 'An error occurred while sending the reset link. Please try again.';
      }
    },
  },
};
</script>
