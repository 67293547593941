<template>
  <TransitionRoot as="template" :show="open" class="z-50">
    <Dialog as="div" class="relative z-10" @close="open = false">
      <div class="fixed inset-0" />
      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
              <DialogPanel class="pointer-events-auto w-screen max-w-md">
                <form  @submit.prevent="generatePodcast" class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                  <div class="h-0 flex-1 overflow-y-auto">
                    <div class="bg-indigo-700 px-4 py-6 sm:px-6">
                        <div class="flex items-center justify-between">
                            <DialogTitle class="text-base font-semibold leading-6 text-white">Generate Your Podcast</DialogTitle>
                            <div class="ml-3 flex h-7 items-center">
                                <button type="button" class="relative rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white" @click="open = false">
                                    <span class="absolute -inset-2.5" />
                                    <span class="sr-only">Close panel</span>
                                    <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                                </button>
                            </div>
                        </div>
                        <div class="mt-1">
                            <p class="text-sm text-indigo-300">Please provide details for your podcast.</p>
                        </div>
                    </div>

                    <div class="flex flex-1 flex-col justify-between">
                      <div class="divide-y divide-gray-200 px-4 sm:px-6">
                        <div class="pt-6">




            <div>
              <div class="border-b border-gray-900/10 pb-4">
                <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div class="sm:col-span-3">
                    <label
                      for="podcastTitle"
                      class="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Podcast Title <span class="text-red-600">*</span>
                    </label>
                    <div class="mt-2">
                      <input
                        type="text"
                        id="podcastTitle"
                        v-model="podcastTitle" 
                        name="podcast_title"
                        required
                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div class="sm:col-span-3">
                    <label
                      for="podcastCategory"
                      class="block text-sm font-medium leading-6 text-gray-900"
                      >Podcast Category
                      <span class="text-red-600">*</span></label
                    >
                    <div class="mt-2">
                      <select
                        id="podcastCategory"
                        name="podcast_category"
                        v-model="podcastCategory"
                        required
                        class="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      >
                        <option selected disabled value="">Choose...</option>
                        <option value="business">Business</option>
                        <option value="comedy">Comedy</option>
                        <option value="education">Education</option>
                        <option value="health_and_wellness">
                          Health & Wellness
                        </option>
                        <option value="news_and_politics">
                          News & Politics
                        </option>
                        <option value="science_and_technology">
                          Science & Technology
                        </option>
                        <option value="history">History</option>
                        <option value="sports">Sports</option>
                        <option value="arts_and_culture">Arts & Culture</option>
                        <option value="fiction">Fiction</option>
                        <option value="true_crime">True Crime</option>
                        <option value="self_improvement">
                          Self-Improvement
                        </option>
                        <option value="other">Other</option>
                      </select>
                    </div>
                  </div>


                <div class="sm:col-span-3">
                    <label
                      for="podcastLength"
                      class="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Podcast Length
                      <span class="text-red-600">*</span>
                    </label>
                    <div class="mt-2">
                      <select
                        id="podcastLength"
                        name="podcast_length"
                        v-model="podcastLength"
                        required
                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      >
                        <option value="" disabled selected>Choose...</option>
                        <option value="1">1 minute</option>
                        <option value="2">2 minutes</option>
                        <option value="3">3 minutes</option>
                        <option value="4">4 minutes</option>
                        <option value="5">5 minutes</option>
                      </select>
                    </div>
                  </div>
      <div class="sm:col-span-3">
                    <label
                      for="podcastFormat"
                      class="block text-sm font-medium leading-6 text-gray-900"
                      >Podcast Format <span class="text-red-600">*</span></label
                    >
                    <div class="mt-2">
                      <select
                        id="podcastFormat"
                        name="podcast_format"
                        v-model="podcastFormat"
                        required
                        class="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      >
                        <option selected disabled value="">Choose...</option>
                        <option value="monologue">Monologue</option>
                        <option value="interview">Interview</option>
                        <option value="conversational">Conversational</option>
                        <option value="narrative_storytelling">
                          Narrative Storytelling
                        </option>
                        <option value="panel_discussion">
                          Panel Discussion
                        </option>
                        <option value="faq">FAQ</option>
                      </select>
                    </div>
                  </div>

                  <div class="sm:col-span-3">
                    <label
                      for="aiPersonality"
                      class="block text-sm font-medium leading-6 text-gray-900"
                      >AI Personality <span class="text-red-600">*</span></label
                    >
                    <div class="mt-2">
                      <select
                        id="aiPersonality"
                        name="ai_personality"
                        v-model="aiPersonality"
                        required
                        class="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      >
                        <option selected disabled value="">Choose...</option>
                        <option value="professional">Professional</option>
                        <option value="casual">Casual</option>
                        <option value="humorous">Humorous</option>
                        <option value="inspirational">Inspirational</option>
                        <option value="informative">Informative</option>
                        <option value="conversational">Conversational</option>
                        <option value="other">Other</option>
                      </select>
                    </div>
                  </div>

                  <div class="sm:col-span-3">
                    <label
                      for="targetAudience"
                      class="block text-sm font-medium leading-6 text-gray-900"
                      >Target Audience
                      <span class="text-red-600">*</span></label
                    >
                    <div class="mt-2">
                      <select
                        id="targetAudience"
                        name="target_audience"
                        required
                        class="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      >
                        <option selected disabled value="">Choose...</option>
                        <option value="children">Children</option>
                        <option value="teens">Teens</option>
                        <option value="young_adults">Young Adults</option>
                        <option value="adults">Adults</option>
                        <option value="seniors">Seniors</option>
                        <option value="all_ages">All Ages</option>
                        <option value="other">Other</option>
                      </select>
                    </div>
                  </div>

                  <div class="sm:col-span-3 space-y-2">
                    <label
                      for="aiVoice1"
                      class="block text-sm font-medium leading-6 text-gray-900"
                      >AI Voice 1 <span class="text-red-600">*</span></label
                    >
                    <select
                      id="aiVoice1"
                      name="ai_voice_1"
                      v-model="aiVoice1"
                      class="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      required
                    >
                      <option selected disabled value="">Choose...</option>
                      <option value="en-US-GuyNeural">
                        Guy (Neural) - American English
                      </option>
                      <option value="en-US-JessaNeural">
                        Jessa (Neural) - American English
                      </option>
                      <option value="en-GB-RyanNeural">
                        Ryan (Neural) - British English
                      </option>
                      <option value="en-GB-LibbyNeural">
                        Libby (Neural) - British English
                      </option>
                      <option value="en-AU-NatashaNeural">
                        Natasha (Neural) - Australian English
                      </option>
                      <option value="en-AU-WilliamNeural">
                        William (Neural) - Australian English
                      </option>
                      <option value="en-CA-ClaraNeural">
                        Clara (Neural) - Canadian English
                      </option>
                      <option value="en-CA-LiamNeural">
                        Liam (Neural) - Canadian English
                      </option>
                      <option value="en-IN-NeerjaNeural">
                        Neerja (Neural) - Indian English
                      </option>
                      <option value="en-IN-PrabhatNeural">
                        Prabhat (Neural) - Indian English
                      </option>
                      <option value="es-ES-ElviraNeural">
                        Elvira (Neural) - Spanish (Spain)
                      </option>
                      <option value="es-ES-AlvaroNeural">
                        Alvaro (Neural) - Spanish (Spain)
                      </option>
                    </select>
                  </div>

                  <div class="sm:col-span-3 space-y-2">
                    <label
                      for="aiVoice2"
                      class="block text-sm font-medium leading-6 text-gray-900"
                      >AI Voice 2 <span class="text-red-600">*</span></label
                    >
                    <select
                      id="aiVoice2"
                      name="ai_voice_2"
                      v-model="aiVoice2"
                      class="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      required
                    >
                      <option selected disabled value="">Choose...</option>
                      <option value="en-US-GuyNeural">
                        Guy (Neural) - American English
                      </option>
                      <option value="en-US-JessaNeural">
                        Jessa (Neural) - American English
                      </option>
                      <option value="en-GB-RyanNeural">
                        Ryan (Neural) - British English
                      </option>
                      <option value="en-GB-LibbyNeural">
                        Libby (Neural) - British English
                      </option>
                      <option value="en-AU-NatashaNeural">
                        Natasha (Neural) - Australian English
                      </option>
                      <option value="en-AU-WilliamNeural">
                        William (Neural) - Australian English
                      </option>
                      <option value="en-CA-ClaraNeural">
                        Clara (Neural) - Canadian English
                      </option>
                      <option value="en-CA-LiamNeural">
                        Liam (Neural) - Canadian English
                      </option>
                      <option value="en-IN-NeerjaNeural">
                        Neerja (Neural) - Indian English
                      </option>
                      <option value="en-IN-PrabhatNeural">
                        Prabhat (Neural) - Indian English
                      </option>
                      <option value="es-ES-ElviraNeural">
                        Elvira (Neural) - Spanish (Spain)
                      </option>
                      <option value="es-ES-AlvaroNeural">
                        Alvaro (Neural) - Spanish (Spain)
                      </option>
                    </select>
                  </div>
                  <div class="sm:col-span-6 space-y-2">
                    <label
                      for="podcastDescription"
                      class="block text-sm font-medium leading-6 text-gray-900"
                      >Podcast Description</label
                    >
                    <textarea
                      id="podcastDescription"
                      name="podcast_description"
                      v-model="podcastDescription"
                      rows="4"
                      class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-shrink-0 justify-end px-4 py-4">
                    <button type="button" class="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" @click="open = false">Cancel</button>
                    <button type="submit" class="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Generate</button>
                  </div>
                </form>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>


<script setup>
import axios from 'axios';
import { ref, defineProps, defineEmits } from 'vue';
import { useStore } from 'vuex'; // Import useStore for Vuex
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { XMarkIcon } from '@heroicons/vue/24/outline';

// Define the props to receive projectId from the parent component
const props = defineProps({
    projectId: {
        type: Number,
        required: true
    }
});
const emit = defineEmits(['audioGenerated']);
const open = ref(true);
const podcastTitle = ref('');
const podcastCategory = ref('');
const podcastLength = ref('');
const podcastFormat = ref('');
const aiPersonality = ref('');
const aiVoice1 = ref('');
const aiVoice2 = ref('');
const podcastDescription = ref('');

const store = useStore(); // Access the Vuex store

const generatePodcast = async () => {
    const podcastData = {
        podcastTitle: podcastTitle.value,
        podcastCategory: podcastCategory.value,
        podcastLength: podcastLength.value,
        podcastFormat: podcastFormat.value,
        aiPersonality: aiPersonality.value,
        aiVoice1: aiVoice1.value,
        aiVoice2: aiVoice2.value,
        podcastDescription: podcastDescription.value,
    };
    store.dispatch('setLoading', true); // Start the loading state
    open.value = false; // Close the slideover immediately

    try {
        await axios.post(`/api/projects/${props.projectId}/generate_podcast`, podcastData);
        console.log('Podcast generation request sent');
        emit('audioGenerated'); // Emit the event to notify that the audio has been generated
    } catch (error) {
        console.error('Error generating podcast:', error);
    } finally {
        store.dispatch('setLoading', false); // Stop the loading state
    }
};
</script>
