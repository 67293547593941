<template>
  <div class="space-y-6">
    <div class="flex justify-between items-center">
      <h2 class="text-base font-semibold leading-7 text-gray-900">Create new podcast series or import existing</h2>
      <div class="flex gap-4">
        <button @click="formStep = 1" type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          + Create new series
        </button>
        <button @click="formStep = 2" type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          Import existing
        </button>
      </div>
    </div>

    <!-- Loading State -->
    <div v-if="isLoading">
      <LoadingSpinner />
    </div>

    <form v-if="formStep === 1 && !isLoading" @submit.prevent="submitForm">
      <div class="space-y-12">
        <div class="border-b border-gray-900/10 pb-12">
          <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div class="sm:col-span-4">
              <label for="seriesName" class="block text-sm font-medium leading-6 text-gray-900">Series Name</label>
              <div class="mt-2 flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                <input type="text" v-model="newPodcast.title" id="seriesName" autocomplete="username" class="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" />
              </div>
            </div>

            <div class="col-span-full">
              <label for="seriesDescription" class="block text-sm font-medium leading-6 text-gray-900">Series Description</label>
              <textarea v-model="newPodcast.description" id="seriesDescription" rows="3" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></textarea>
            </div>

            <div class="col-span-full">
              <Listbox as="div" v-model="selectedCategory">
                <ListboxLabel class="block text-sm font-medium leading-6 text-gray-900">Series Category</ListboxLabel>
                <div class="relative mt-2">
                  <ListboxButton class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    <span class="block truncate">{{ selectedCategory.name }}</span>
                    <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </ListboxButton>

                  <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                    <ListboxOptions class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      <ListboxOption 
                        v-for="category in categories" 
                        :key="category.id" 
                        :value="category" 
                        v-slot="{ active, selected }">
                        <li :class="[active ? 'bg-indigo-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                          <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{ category.name }}</span>
                          <span v-if="selected" :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                          </span>
                        </li>
                      </ListboxOption>
                    </ListboxOptions>
                  </transition>
                </div>
              </Listbox>
            </div>

            <div class="col-span-full">
              <label for="coverPhoto" class="block text-sm font-medium leading-6 text-gray-900">Series Cover Photo</label>
              <div class="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                <div class="text-center">
                  <PhotoIcon class="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                  <div class="mt-4 flex text-sm leading-6 text-gray-600">
                    <button type="button" class="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500" @click="triggerCoverPhotoUpload">
                      <span>Upload a file</span>
                      <input type="file" id="coverPhoto" ref="coverPhoto" @change="handleCoverPhotoUpload" class="sr-only" />
                    </button>
                    <p class="pl-1">or drag and drop</p>
                  </div>
                  <p class="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 10MB</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-6 flex items-center justify-end gap-x-6">
          <button type="button" class="text-sm font-semibold leading-6 text-gray-900">Cancel</button>
          <button type="submit" class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Save</button>
        </div>
      </div>    
    </form>

    <form v-if="formStep === 2 && !isLoading" @submit.prevent="importSeries">
      <div>
        <input type="url" id="rssFeed" v-model="rssFeed" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm sm:text-sm" placeholder="Enter RSS feed URL" required>
      </div>
      <div class="mt-6 flex items-center justify-end gap-x-6">
        <button type="submit" class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Import</button>
      </div>
    </form>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue';
import { CheckIcon, ChevronUpDownIcon, PhotoIcon } from '@heroicons/vue/24/solid';

const categories = [
  { id: 1, name: 'Arts and Entertainment' },
  { id: 2, name: 'Business and Technology' },
  { id: 3, name: 'Comedy' },
  { id: 4, name: 'Educational' },
  { id: 5, name: 'Games' },
  { id: 6, name: 'Kids and Family' },
  { id: 7, name: 'Lifestyle and Health' },
  { id: 8, name: 'Music' },
  { id: 9, name: 'News and Politics' },
  { id: 10, name: 'Society and Culture' },
  { id: 11, name: 'Sports and Recreation' },
  { id: 12, name: 'Stories' },
  { id: 13, name: 'True Crime' },
]

const selectedCategory = ref(categories[0]);
const newPodcast = ref({
  title: '',
  description: '',
  categoryId: selectedCategory.value.id,
  coverPhoto: null
});

const router = useRouter();
const formStep = ref(0); // 0: Choose, 1: Create New, 2: Import Existing
const rssFeed = ref('');
const isLoading = ref(false);

const handleCoverPhotoUpload = event => {
  newPodcast.value.coverPhoto = event.target.files[0];
};

const triggerCoverPhotoUpload = () => {
  document.getElementById('coverPhoto').click();
};

const submitForm = async () => {
  isLoading.value = true;
  try {
    const formData = new FormData();
    formData.append('title', newPodcast.value.title);
    formData.append('description', newPodcast.value.description);
    formData.append('category', selectedCategory.value.name);
    formData.append('coverPhoto', newPodcast.value.coverPhoto);

    await axios.post('/api/podcasts', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    router.push('/studio/podcasts');
  } catch (error) {
    console.error('Error creating podcast:', error);
  } finally {
    isLoading.value = false;
  }
};

const importSeries = async () => {
  isLoading.value = true;
  try {
    await axios.post('/api/podcasts/import', { rssFeed: rssFeed.value });
    router.push('/studio/podcasts');
  } catch (error) {
    console.error('Error importing podcast series:', error);
  } finally {
    isLoading.value = false;
  }
};
</script>
