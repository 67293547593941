<template>
  <StudioLayout>
    <!-- Loading State -->
    <div v-if="isLoading">
      <LoadingSpinner />
    </div>

    <!-- Content State -->
    <div v-else class="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
      <!-- Header with Title, Description, and Buttons -->
      <div class="px-4 py-5 sm:px-6 md:flex md:items-center md:justify-between">
        <div class="min-w-0 flex-1">
          <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:tracking-tight">{{ episode.title }}</h2>
          <p class="mt-2 text-sm text-gray-600">{{ episode.description }}</p>
          <p class="mt-1 text-sm text-gray-500">Published on: {{ publishedDateFormatted }}</p>
        </div>
        <div class="mt-4 flex md:mt-0 md:ml-4">
          <a :href="episode.audio_url" download class="ml-3 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700">
            Export
          </a>
        </div>
      </div>

      <!-- Audio Player -->
      <div class="px-4 py-5 sm:p-6">
        <audio v-if="episode.audio_url" controls class="w-full">
          <source :src="episode.audio_url" type="audio/mpeg">
          Your browser does not support the audio element.
        </audio>
        <p v-else class="text-red-500">Audio not available for this episode.</p>
      </div>
    </div>
  </StudioLayout>
</template>
<script>
import StudioLayout from '@/components/StudioLayout.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import axios from 'axios';
import { ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

export default {
  components: {
    StudioLayout,
    LoadingSpinner,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const episode = ref({
      title: '',
      description: '',
      published_date: '',
      audio_url: '',
    });
    const isLoading = computed(() => store.getters.isLoading);

    const fetchEpisode = async () => {
      store.dispatch('setLoading', true);
      try {
        const episodeId = route.params.episodeId;
        const response = await axios.get(`/api/episodes/${episodeId}`);
        episode.value = response.data;
      } catch (error) {
        console.error("Error fetching episode data:", error);
      } finally {
        store.dispatch('setLoading', false);
      }
    };

    onMounted(fetchEpisode);

    const publishedDateFormatted = computed(() => {
      return episode.value.published_date ? new Date(episode.value.published_date).toLocaleDateString() : '';
    });

    return { episode, isLoading, publishedDateFormatted };
  }
}
</script>
