<template>
  <div class="flex min-h-full flex-1 items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
    <div class="w-full max-w-sm space-y-10">
      <div>
        <a href='/' ><img class="mx-auto h-10 w-auto" src="@/assets/logo-main.png" alt="Your Company" /></a>
        <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Sign in to your account</h2>
      </div>
      <form class="space-y-6" @submit.prevent="signIn">
        <div class="relative -space-y-px rounded-md shadow-sm">
          <!-- Email Input -->
          <div>
            <label for="email-address" class="sr-only">Email address</label>
            <input v-model="email" id="email-address" name="email" type="email" autocomplete="email" required class="relative block w-full rounded-t-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Email address" />
          </div>
          <!-- Password Input -->
          <div>
            <label for="password" class="sr-only">Password</label>
            <input v-model="password" id="password" name="password" type="password" autocomplete="current-password" required class="relative block w-full rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Password" />
          </div>
        </div>

        <div class="flex items-center justify-between">
          <!-- Remember Me Checkbox -->
          <div class="flex items-center">
            <input id="remember-me" name="remember-me" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
            <label for="remember-me" class="ml-3 block text-sm leading-6 text-gray-900">Remember me</label>
          </div>
          <!-- Forgot Password Link -->
          <div class="text-sm leading-6">
            <a href="/forgot-password" class="font-semibold text-indigo-600 hover:text-indigo-500">Forgot password?</a>
          </div>
        </div>

        <!-- Sign In Button -->
        <div>
          <button type="submit" class="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Sign in</button>
        </div>
      </form>

      <!-- Error Message and Resend Verification Link -->
      <div v-if="loginError" class="mt-3 text-center text-sm font-medium text-red-600">
        {{ loginError }}
        <a v-if="showResendVerification" href="#" @click="resendVerification" class="block mt-2 text-indigo-600 hover:text-indigo-500">Resend Verification Email</a>
      </div>

      <!-- Registration Link -->
      <p class="text-center text-sm leading-6 text-gray-500">
        Not a member?
        <a href="/signup" class="font-semibold text-indigo-600 hover:text-indigo-500">Register here</a>
      </p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      email: '',
      password: '',
      loginError: '',
      showResendVerification: false, // Flag to show/hide the resend verification link
    };
  },
  methods: {
    async signIn() {
      try {
        const response = await axios.post('/api/login', {
          email: this.email,
          password: this.password,
        });
        if (response.data.message === "Login successful") {
          // Store the token in local storage
          localStorage.setItem('userToken', response.data.token);
          this.$router.push('/studio/podcasts');
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 401 && error.response.data.message === "Invalid email or password.") {
            this.loginError = "Invalid email or password.";
            this.showResendVerification = false;
          } else if (error.response.status === 403 && error.response.data.message === "Email not verified") {
            this.loginError = "Email not verified.";
            this.showResendVerification = true;
          } else {
            this.loginError = "An error occurred during the login process.";
            this.showResendVerification = false;
          }
        }
      }
    },
    async resendVerification() {
  try {
    await axios.post('/api/resend-verification', { email: this.email });
    this.loginError = "A new verification email has been sent to your email address.";
    this.showResendVerification = false;
  } catch (error) {
    if (error.response) {
      // Provide more specific feedback based on the error response from the server
      this.loginError = error.response.data.error || 'Failed to resend verification email. Please try again later.';
    } else {
      this.loginError = 'Failed to resend verification email. Please try again later.';
    }
  }
}




  }
};
</script>
