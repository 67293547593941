// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router/index';
import store from './store';
import axios from 'axios';
import VueGtag from "vue-gtag";

import './assets/css/main.css';

// Set Axios base URL based on environment variable
// axios.defaults.baseURL = process.env.VUE_APP_BACKEND_BASE_URL;

// Axios interceptor
axios.interceptors.request.use(function(config) {
  const token = localStorage.getItem('userToken');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

const app = createApp(App);
app.use(router);
app.use(store);

// Initialize Google Analytics
if (location.hostname !== 'localhost') { // Check if not in localhost
  app.use(VueGtag, {
    config: { id: "G-QTFSN7Y5NJ" }
  }, router);
}

app.mount('#app');
