<template>
  <div class="podcast-player-view flex h-screen items-center justify-center">
    <div class="w-full max-w-md">
      <AudioPlayer :audioUrl="audioUrl" :podcastTitle="podcastTitle" />
    </div>
  </div>
</template>

<script>
import AudioPlayer from '@/components/AudioPlayer.vue';
import axios from 'axios';

export default {
  components: {
    AudioPlayer,
  },
  data() {
    return {
      podcastTitle: 'Podcast Title',
      audioUrl: null,
    };
  },
  async created() {
    const projectId = this.$route.params.projectId;
    try {
      const response = await axios.get(`/api/public/projects/${projectId}/audio`);
      this.podcastTitle = response.data.title;
      this.audioUrl = response.data.audio_url;
    } catch (error) {
      console.error('Error fetching podcast details:', error);
    }
  },
};
</script>


