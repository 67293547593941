<template>
  <div class="flex min-h-full flex-1 items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
    <div class="w-full max-w-sm space-y-10">
      <div>
        <img class="mx-auto h-10 w-auto" src="@/assets/logo-main.png" alt="Your Company" />
        <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Finalizing Your Registration
        </h2>
      </div>
      <div class="text-center">
        <p class="text-sm text-gray-600">
          Please check your email for a verification link to activate your account.
        </p>
        <p v-if="message" class="mt-4 text-sm text-red-600">
          {{ message }}
        </p>
      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';

export default {
  data() {
    return {
      message: '',
    };
  },
  methods: {
    async finalizeRegistration() {
      const queryParams = new URLSearchParams(window.location.search);
      const sessionId = queryParams.get('session_id');
      if (sessionId) {
        const email = localStorage.getItem('userEmail');
        if (email) {
          try {
            await axios.post('/api/finalize-registration', { email });
            // Optionally, display a success message or redirect
          } catch (error) {
            console.error('Error:', error);
            this.message = 'An error occurred while finalizing your registration. Please try again.';
          }
        } else {
          this.message = 'Unable to retrieve your email address. Please ensure you are following the correct registration process.';
        }
      } else {
        this.message = 'No registration session found. Please complete the registration process.';
      }
    },
  },
  mounted() {
    this.finalizeRegistration();
  },
};
</script>
