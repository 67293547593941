<template>
  <TransitionRoot as="template" :show="showModal" class="z-50">
    <Dialog as="div" class="relative z-10" @close="close">
      <div class="fixed inset-0"></div>
      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
              <DialogPanel class="pointer-events-auto w-screen max-w-md">
                <form @submit.prevent="handleExport" class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                  <div class="h-0 flex-1 overflow-y-auto">
                    <div class="bg-indigo-700 px-4 py-6 sm:px-6">
                      <div class="flex items-center justify-between">
                        <DialogTitle class="text-lg font-medium text-white">Export Your Podcast</DialogTitle>
                        <div class="ml-3 flex h-7 items-center">
                          <button type="button" class="rounded-md text-indigo-200 hover:text-white" @click="close">
                            <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <div class="mt-1">
                        <p class="text-sm text-indigo-300">Choose how you'd like to export or share your podcast.</p>
                      </div>
                    </div>
                    <div class="divide-y divide-gray-200 px-4 sm:px-6">
                      <div class="space-y-6 py-6">
                        <div>
                          <label for="export-type" class="block text-sm font-medium text-gray-900">Export Type</label>
                          <select id="export-type" name="export-type" class="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" v-model="exportType">
                            <option value="mp3">MP3</option>
                            <!-- <option value="wav">WAV</option> -->
                          </select>
                        </div>
                      </div>
                      <div class="space-y-6 py-6">
                        <div>
                          <h3 class="text-lg font-medium leading-6 text-gray-900">Share</h3>
                          <p class="mt-1 text-sm text-gray-500">Use these links to share your podcast.</p>
                        </div>
                        <div>
                          <label for="player-link" class="block text-sm font-medium text-gray-900">Player Link</label>
                          <div class="mt-1 flex rounded-md shadow-sm">
                            <input type="text" name="player-link" id="player-link" class="flex-1 block w-full rounded-none rounded-l-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" :value="playerLink" readonly>
                            <button @click="copyToClipboard('player-link')" type="button" class="group inline-flex items-center rounded-r-md border border-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-50">
                              <ClipboardIcon class="mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                              Copy
                              <span class="ml-2 inline-block rounded-full bg-green-100 px-2 py-1 text-xs font-semibold text-green-800" v-show="copySuccessPlayerLink">Copied!</span>
                            </button>
                          </div>
                        </div>
                        <div>
                          <label for="iframe-link" class="block text-sm font-medium text-gray-900">Embeddable Iframe</label>
                          <div class="mt-1 flex rounded-md shadow-sm">
                            <input type="text" name="iframe-link" id="iframe-link" class="flex-1 block w-full rounded-none rounded-l-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" :value="iframeCode" readonly>
                            <button @click="copyToClipboard('iframe-link')" type="button" class="group inline-flex items-center rounded-r-md border border-gray-300 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-50">
                              <ClipboardIcon class="mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                              Copy
                              <span class="ml-2 inline-block rounded-full bg-green-100 px-2 py-1 text-xs font-semibold text-green-800" v-show="copySuccessIframeLink">Copied!</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-shrink-0 justify-end px-4 py-4">
                    <button type="button" class="rounded-md bg-white py-2 px-4 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" @click="close">Cancel</button>
                    <button type="submit" class="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Export</button>
                  </div>
                </form>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { ref, defineProps, defineEmits, computed } from 'vue';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { XMarkIcon, ClipboardIcon } from '@heroicons/vue/24/outline';
import axios from 'axios';

const props = defineProps({
  projectId: Number
});

const emit = defineEmits(['update:open']);
const exportType = ref('mp3'); // Default to 'mp3'
const showModal = ref(true); // Use a local state to control the modal's visibility
const copySuccessPlayerLink = ref(false); // New state for player link copy success
const copySuccessIframeLink = ref(false); // New state for iframe link copy success

const playerLink = computed(() => `${window.location.origin}/player/${props.projectId}`);
const iframeCode = computed(() => `<iframe src="${playerLink.value}" width="100%" height="300"></iframe>`);

const close = () => {
  showModal.value = false;
  emit('update:open', false);
};

const handleExport = async () => {
  try {
    const response = await axios.get(`/api/projects/${props.projectId}/download_audio`);
    const downloadUrl = response.data.download_url;

    if (downloadUrl) {
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', `podcast.${exportType.value}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      close();
    } else {
      console.error('Download URL not found in the response.');
    }
  } catch (error) {
    console.error('Error fetching download URL:', error);
  }
};

const copyToClipboard = (elementId) => {
  const inputElement = document.getElementById(elementId);
  inputElement.select();
  document.execCommand('copy');
  if (elementId === 'player-link') {
    copySuccessPlayerLink.value = true;
    setTimeout(() => copySuccessPlayerLink.value = false, 2000);
  } else if (elementId === 'iframe-link') {
    copySuccessIframeLink.value = true;
    setTimeout(() => copySuccessIframeLink.value = false, 2000);
  }
};
</script>
