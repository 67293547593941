<template>
  <ul role="list" class="divide-y divide-gray-100">
    <li v-for="episode in episodes" :key="episode.id" class="flex items-center justify-between gap-x-6 py-5">
      <div class="min-w-0 flex-1">
        <div class="flex items-start gap-x-3">
          <p class="text-sm font-semibold leading-6 text-gray-900">{{ episode.title }}</p>
          <!-- <p :class="[statusClasses[episode.status], 'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset']">{{ episode.status }}</p> -->
        </div>
        <div class="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
          <p>Published on <time :datetime="episode.published_date">{{ episode.publishedDateFormatted }}</time></p>
          <!-- <p class="truncate">Created by {{ episode.createdBy }}</p> -->
        </div>
      </div>
      <div class="flex flex-none items-center gap-x-4">
<a :href="`/studio/podcasts/${podcastId}/${episode.id}`" class="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
  View episode<span class="sr-only">, {{ episode.title }}</span>
</a>
        <!-- Dropdown Menu for More Options -->
        <Menu as="div" class="relative flex-none">
          <MenuButton class="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
            <span class="sr-only">Open options</span>
            <EllipsisVerticalIcon class="h-5 w-5" aria-hidden="true" />
          </MenuButton>
          <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
            <MenuItems class="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-black/10 focus:outline-none">
              <!-- <MenuItem as="div">
                <a href="#" class="block px-3 py-1 text-sm leading-6 text-gray-900">Edit<span class="sr-only">, {{ episode.title }}</span></a>
              </MenuItem> -->
              <MenuItem v-slot="{ active }">
                <a @click="deleteEpisode(episode)" :class="[active ? 'bg-gray-50' : '', 'block px-3 py-1 text-sm leading-6 text-gray-900']">
                  Delete<span class="sr-only">, {{ episode.title }}</span>
                </a>
              </MenuItem>

            </MenuItems>
          </transition>
        </Menu>
      </div>
    </li>
  </ul>
</template>

<script setup>
import { ref, onMounted, defineProps } from 'vue';
import axios from 'axios';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { EllipsisVerticalIcon } from '@heroicons/vue/20/solid';

// const statusClasses = {
//   'Complete': 'text-green-700 bg-green-50 ring-green-600/20',
//   'In progress': 'text-gray-600 bg-gray-50 ring-gray-500/10',
//   'Archived': 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
// };

const props = defineProps({
  podcastId: Number
});

const episodes = ref([]);

const deleteEpisode = async (episode) => {
  try {
    await axios.delete(`/api/podcasts/${props.podcastId}/episodes/${episode.id}`);
    episodes.value = episodes.value.filter(e => e.id !== episode.id);
  } catch (error) {
    console.error("Error deleting episode: ", error);
  }
};


onMounted(async () => {
  try {
    const response = await axios.get(`/api/podcasts/${props.podcastId}/episodes`);
    episodes.value = response.data.map(episode => ({
      ...episode,
      publishedDateFormatted: new Date(episode.published_date).toLocaleDateString(),
    }));
  } catch (error) {
    console.error("Error fetching episodes: ", error);
  }
});
</script>

<style scoped>
/* Add your CSS here */
</style>
