<!-- ProjectPage.vue -->

<template>
    <ProjectPageStudioLayout>
      <div class="overflow-hidden rounded-lg bg-white shadow">

      <div class="px-4 py-5 sm:p-6">
        <ZoomMeeting />

      </div>
      <div class="px-4 py-4 sm:px-6">
      </div>
      </div>
    
    </ProjectPageStudioLayout>
  </template>
  
  <script>

import ProjectPageStudioLayout from '@/components/ProjectPageStudioLayout.vue';
import ZoomMeeting from '@/components/ZoomMeeting.vue';


  export default {
    components: {
    ProjectPageStudioLayout,
    ZoomMeeting
},
  }
  </script>
