<template>
  <div class="flex min-h-full flex-1 items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
    <div class="w-full max-w-sm space-y-10">
      <div>
        <a href='/' ><img class="mx-auto h-10 w-auto" src="@/assets/logo-main.png" alt="Your Company" /></a>
        <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Register</h2>
      </div>
      <form class="space-y-6" @submit.prevent="register">
        <div class="relative -space-y-px rounded-md shadow-sm">
          <div class="pointer-events-none absolute inset-0 z-10 rounded-md ring-1 ring-inset ring-gray-300" />
          <div>
            <label for="username" class="sr-only">Username</label>
            <input v-model="username" id="username" name="username" type="text" required class="relative block w-full rounded-t-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Username" />
          </div>
          <div>
            <label for="email-address" class="sr-only">Email address</label>
            <input v-model="email" id="email-address" name="email" type="email" autocomplete="email" required class="relative block w-full rounded-t-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Email address" />
          </div>
          <div>
            <label for="password" class="sr-only">Password</label>
            <input v-model="password" id="password" name="password" type="password" autocomplete="current-password" required class="relative block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Password" />
          </div>
          <div>
            <label for="password-confirm" class="sr-only">Confirm Password</label>
            <input v-model="confirmPassword" id="password-confirm" name="password-confirm" type="password" required class="relative block w-full rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Confirm Password" />
          </div>
        </div>

        <!-- Terms and Privacy checkbox -->
        <!-- <div class="flex items-center">
          <input v-model="termsAgreed" id="terms-checkbox" name="terms-checkbox" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 mr-2" required />
          <label for="terms-checkbox" class="ml-3 block text-sm leading-6 text-gray-900">
            I have read and agree to the
            <a href="/terms" target="_blank" class="font-semibold text-indigo-600 hover:text-indigo-500 underline">Terms and Conditions</a>
            and 
            <a href="/privacy" target="_blank" class="font-semibold text-indigo-600 hover:text-indigo-500 underline">Privacy Policy</a>
            <span class="text-red-600">*</span>
          </label>
        </div> -->
        <div>
          <button type="submit" class="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Sign up</button>
        </div>
      </form>
              <!-- Display the registration message right before the submit button -->
      <div v-if="registrationMessage" class="text-center text-sm text-red-600">
          {{ registrationMessage }}
        </div>

      <p v-if="showVerificationMessage" class="text-green-600">
          Registration successful! Please check your email to verify your account.
          <a href="/signin" class="text-indigo-600 hover:text-indigo-500">Go to Login</a>
        </p>
      <p class="text-center text-sm leading-6 text-gray-500">
        Already signed up?
        <a href="/signin" class="font-semibold text-indigo-600 hover:text-indigo-500">Log in here</a>
      </p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            username: '',
            email: '',
            password: '',
            confirmPassword: '',
            registrationMessage: '',
            showVerificationMessage: false,
        };
    },
    methods: {
        async register() {
            if (this.password !== this.confirmPassword) {
                this.registrationMessage = "Passwords do not match";
                return;
            }

            try {
                const response = await axios.post('/api/register', {
                    username: this.username,
                    email: this.email,
                    password: this.password,
                });

                // Stripe checkout process commented out
                // if (response.data.sessionId) {
                //     const stripe = await loadStripe('your_stripe_public_key_here');
                //     stripe.redirectToCheckout({ sessionId: response.data.sessionId });
                // } else {
                //     this.registrationMessage = "Failed to initiate payment process.";
                // }

                // Handle successful registration
                if (response.data.message === "Registration successful. Please verify your email.") {
                    this.showVerificationMessage = true;
                } else {
                    this.registrationMessage = response.data.message || "Unexpected error occurred.";
                }
            } catch (error) {
                this.registrationMessage = "Registration failed. Please try again.";
            }
        },
    },
};
</script>

