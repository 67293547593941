<template>
  <div class="audio-player bg-white shadow sm:rounded-lg max-w-md mx-auto">
    <div class="px-4 py-5 sm:p-6 flex justify-between items-center">
      <div>
        <h3 class="text-lg font-semibold leading-6 text-gray-900">{{ podcastTitle }}</h3>
      </div>
      <!-- Logo with hyperlink -->
      <a href="https://poddy.ai/" target="_blank">
        <img class="h-8 w-auto" src="@/assets/images/logo.png" alt="Poddy AI" />
      </a>
    </div>

    
    <!-- Audio Element -->
    <audio ref="audioElement" @timeupdate="updateProgress" @ended="isPlaying = false" v-if="audioUrl" class="hidden">
      <source :src="audioUrl" type="audio/mpeg">
      Your browser does not support the audio element.
    </audio>

    <!-- Controls -->
    <div class="px-4 pb-4 flex items-center justify-between">
      <div class="flex items-center">
        <button @click="skipTime(-10)" class="text-gray-500 hover:text-gray-700">
          <BackwardIcon class="h-6 w-6" />
        </button>
        <button @click="togglePlay" class="mx-4">
          <PlayIcon v-if="!isPlaying" alt="Play" class="h-8 w-8 text-indigo-500" />
          <PauseIcon v-else alt="Pause" class="h-8 w-8 text-indigo-500" />
        </button>
        <button @click="skipTime(10)" class="text-gray-500 hover:text-gray-700">
          <ForwardIcon class="h-6 w-6" />
        </button>
      </div>

      
      <!-- Progress Bar -->
      <input type="range" class="flex-1 mx-4" min="0" :max="duration" v-model="currentTime" @input="seek">

      <!-- Volume Control -->
      <!-- <div class="flex items-center">
        <VolumeOffIcon class="h-5 w-5 text-gray-500 mr-2" @click="volume = 0" />
        <input type="range" min="0" max="1" step="0.1" v-model="volume" @input="changeVolume" class="w-24">
        <VolumeUpIcon class="h-5 w-5 text-gray-500 ml-2" @click="volume = 1" />
      </div> -->
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import {
  PlayIcon,
  PauseIcon,
  // VolumeOffIcon,
  // VolumeUpIcon,
  BackwardIcon,
  ForwardIcon,
} from '@heroicons/vue/24/outline';

export default {
  props: {
    audioUrl: String,
    podcastTitle: String,
  },
  setup(props) {
    const audioElement = ref(null);
    const isPlaying = ref(false);
    const currentTime = ref(0);
    const duration = ref(0);
    const volume = ref(1);

    watch(() => props.audioUrl, (newVal) => {
      if (newVal) {
        resetPlayer();
      }
    });

    const togglePlay = () => {
      if (!audioElement.value) return;
      if (isPlaying.value) {
        audioElement.value.pause();
      } else {
        audioElement.value.play();
      }
      isPlaying.value = !isPlaying.value;
    };

    const updateProgress = () => {
      currentTime.value = audioElement.value.currentTime;
      duration.value = audioElement.value.duration;
    };

    const seek = (event) => {
      if (!audioElement.value) return;
      audioElement.value.currentTime = event.target.value;
    };

    const changeVolume = (event) => {
      if (!audioElement.value) return;
      audioElement.value.volume = event.target.value;
    };

    const skipTime = (time) => {
      if (!audioElement.value) return;
      audioElement.value.currentTime += time;
    };

    const resetPlayer = () => {
      currentTime.value = 0;
      isPlaying.value = false;
      if (audioElement.value) {
        audioElement.value.pause();
        audioElement.value.load();
        audioElement.value.volume = volume.value;
      }
    };

    return {
      audioElement,
      isPlaying,
      currentTime,
      duration,
      volume,
      togglePlay,
      updateProgress,
      seek,
      changeVolume,
      skipTime,
      resetPlayer,
    };
  },
  components: {
    PlayIcon,
    PauseIcon,
    // VolumeOffIcon,
    // VolumeUpIcon,
    // RewindIcon,
    // FastForwardIcon,
    BackwardIcon,
    ForwardIcon
},
};
</script>

