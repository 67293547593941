<!--
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
-->
<template>
  <!--
    This example requires updating your template:

    ```
    <html class="h-full bg-white">
    <body class="h-full">
    ```
  -->
  <div>
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog as="div" class="relative z-50 lg:hidden" @close="sidebarOpen = false">
        <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-900/80" />
        </TransitionChild>

        <div class="fixed inset-0 flex">
          <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
            <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
              <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
                <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
                  <button type="button" class="-m-2.5 p-2.5" @click="sidebarOpen = false">
                    <span class="sr-only">Close sidebar</span>
                    <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              <!-- Sidebar component, swap this element with another sidebar if you like -->
              <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4 ring-1 ring-white/10">
                <div class="flex h-16 shrink-0 items-center">
                  <img class="h-8 w-auto" src="@/assets/favicon-white.png" alt="Your Company" />
                </div>
                <nav class="flex flex-1 flex-col">
                  <ul role="list" class="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul role="list" class="-mx-2 space-y-1">
                        <li v-for="item in navigation" :key="item.name">
                          <a :href="item.href" :class="[item.current ? 'bg-gray-800 text-white' : 'text-gray-400 hover:text-white hover:bg-gray-800', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']">
                            <component :is="item.icon" class="h-6 w-6 shrink-0" aria-hidden="true" />
                            {{ item.name }}
                          </a>
                        </li>
                      </ul>
                    </li>
                    <!-- <li>
                    <div class="text-xs font-semibold leading-6 text-gray-400">Your podcasts</div>
                      <ul role="list" class="-mx-2 mt-2 space-y-1">
                        <li v-for="team in teams" :key="team.name">
                          <a :href="team.href" :class="[team.current ? 'bg-gray-800 text-white' : 'text-gray-400 hover:text-white hover:bg-gray-800', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']">
                            <span class="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">{{ team.initial }}</span>
                            <span class="truncate">{{ team.name }}</span>
                          </a>
                        </li>
                      </ul>
                    </li> -->
                    <li class="mt-auto">
                      <a href="/studio/profile" class="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white">
                        <Cog6ToothIcon class="h-6 w-6 shrink-0" aria-hidden="true" />
                        Settings
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>

<!-- Static sidebar for desktop -->
<div :class="collapsed ? 'hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-20 lg:flex-col' : 'hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col'">
    <!-- Sidebar component -->
    <div class="flex flex-grow flex-col overflow-y-auto bg-gray-900 px-6 pb-4">
        <div class="flex h-16 shrink-0 items-center justify-between">
            <img class="h-8 w-auto" src="@/assets/favicon-white.png" alt="Your Company" />
            <!-- button 
            <button @click="toggleCollapse" class="text-gray-400 hover:text-white focus:outline-none">
                <Bars3Icon v-if="!collapsed" class="h-6 w-6" />
                <XMarkIcon v-else class="h-6 w-6" />
            </button>
            -->
        </div>
        <div class="flex flex-1 flex-col">
            <nav class="flex-grow">
                <ul role="list" class="space-y-7">
                    <li>
                        <ul role="list" class="-mx-2 space-y-1">
                            <li v-for="item in navigation" :key="item.name">
                                <a :href="item.href" :class="[item.current ? 'bg-gray-800 text-white' : 'text-gray-400 hover:text-white hover:bg-gray-800', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']">
                                    <component :is="item.icon" class="h-6 w-6 shrink-0" aria-hidden="true" />
                                    <span v-if="!collapsed">{{ item.name }}</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <!-- Uncomment below for podcasts
                    <li>
                        <div v-if="!collapsed" class="text-xs font-semibold leading-6 text-gray-400">Your podcasts</div>
                        <ul role="list" class="-mx-2 mt-2 space-y-1">
                            <li v-for="team in teams" :key="team.name">
                                <a :href="team.href" :class="[team.current ? 'bg-gray-800 text-white' : 'text-gray-400 hover:text-white hover:bg-gray-800', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']">
                                    <span class="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-gray-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">{{ team.initial }}</span>
                                    <span v-if="!collapsed" class="truncate">{{ team.name }}</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                    -->
                </ul>
            </nav>
            <div class="mt-auto">
                <a href="/studio/profile" class="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white">
                    <Cog6ToothIcon class="h-6 w-6 shrink-0" aria-hidden="true" />
                    <span v-if="!collapsed">Settings</span>
                </a>
            </div>
        </div>
    </div>
</div>

    <div :class="collapsed ? 'lg:pl-20' : 'lg:pl-72'">

      <div class="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
        <button type="button" class="-m-2.5 p-2.5 text-gray-700 lg:hidden" @click="sidebarOpen = true">
          <span class="sr-only">Open sidebar</span>
          <Bars3Icon class="h-6 w-6" aria-hidden="true" />
        </button>

        <!-- Separator -->
        <div class="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true" />

        <div class="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">

          <nav class="flex relative flex flex-1" aria-label="Breadcrumb">
            <ol role="list" class="flex items-center space-x-4">
              <li>
                <div>
                  <a href="/studio/podcasts" class="text-gray-400 hover:text-gray-500">
                    <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                    <span class="sr-only">Home</span>
                  </a>
                </div>
              </li>
              <li v-for="page in pages" :key="page.name">
                <div class="flex items-center">
                  <ChevronRightIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                  <a :href="page.href" class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700" :aria-current="page.current ? 'page' : undefined">{{ page.name }}</a>
                </div>
              </li>
            </ol>
          </nav>



          <div class="flex items-center gap-x-4 lg:gap-x-6">
            <!-- <button type="button" class="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500">
              <span class="sr-only">View notifications</span>
              <BellIcon class="h-6 w-6" aria-hidden="true" />
            </button> -->

            <!-- Separator -->
            <div class="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10" aria-hidden="true" />

            <!-- Profile dropdown -->
            <Menu as="div" class="relative">
              <MenuButton class="-m-1.5 flex items-center p-1.5">
                  <span class="sr-only">Open user menu</span>
                  <!-- Icon with the first letter of the username -->
                  <span class="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-gray-700 bg-white text-gray-800 text-sm font-medium">{{ username.charAt(0).toUpperCase() }}</span>
                  <span class="hidden lg:flex lg:items-center">
                      <span class="ml-4 text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">{{ username }}</span>
                      <ChevronDownIcon class="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
              </MenuButton>
              <MenuItems class="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
  <MenuItem v-for="item in userNavigation" :key="item.name" v-slot="{ active }">
    <a 
      :href="item.name !== 'Sign out' ? item.href : '#'" 
      :class="[active ? 'bg-gray-50' : '', 'block px-3 py-1 text-sm leading-6 text-gray-900']" 
      @click="item.name === 'Sign out' && logout()">
      {{ item.name }}
    </a>
  </MenuItem>
</MenuItems>


            </Menu>
          </div>

        </div>
      </div>

      <main class="relative py-10 flex-grow"> <!-- added relative positioning and flex-grow -->
          <div class="px-4 sm:px-6 lg:px-8 h-full"> <!-- adjusted to take full height -->
              <slot></slot>
          </div>
      </main>

      
    </div>

  </div>
</template>
<script>
import axios from 'axios';
import {
    Dialog,
    DialogPanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
} from '@headlessui/vue';
import {
    Bars3Icon,
    BellIcon,
    CalendarIcon,
    ChartPieIcon,
    Cog6ToothIcon,
    DocumentDuplicateIcon,
    FolderIcon,
    BookOpenIcon,
    MicrophoneIcon,
    UsersIcon,
    XMarkIcon,
    ArrowUpOnSquareIcon,
    LightBulbIcon,
    SpeakerWaveIcon,
} from '@heroicons/vue/24/outline';
import { 
    ChevronDownIcon, 
    MagnifyingGlassIcon, 
    ChevronRightIcon,
    ArrowRightCircleIcon,
    PaperAirplaneIcon,
    HomeIcon
} from '@heroicons/vue/20/solid';

import {
    BackwardIcon,
    PlayPauseIcon,
    ForwardIcon,
} from '@heroicons/vue/24/outline';

export default {
    name: 'ProjectPageStudioLayout',
    data() {
        return {
            sidebarOpen: false,
            collapsed: true,
            navigation: [
                { name: 'Podcasts', href: '/studio/podcasts', icon: MicrophoneIcon, current: this.$route.path === '/studio/podcasts' },
                { name: 'Projects', href: '/studio/projects', icon: FolderIcon, current: this.$route.path === '/studio/projects' },
                { name: 'Docs', href: 'https://docs.poddy.ai/', icon: BookOpenIcon, current: this.$route.path === '/studio/projects' },
            ],
            teams: [
                { id: 1, name: 'Rest is History', href: '/studio/podcasts/1', initial: 'R', current: false },
                { id: 2, name: 'True crime with Kevin Fendergast', href: '/studio/podcasts/1', initial: 'T', current: false },
                { id: 3, name: 'Orbital Exchange', href: '/studio/podcasts/1', initial: 'O', current: false },
            ],
            userNavigation: [
                // { name: 'Documentation', href: '/studio/profile' },
                { name: 'Support', href: 'mailto:support@poddy.ai' },
                { name: 'Sign out', href: '#', onClick: this.logout }, // Add logout method
            ],
            pages: [],
            username: '', // Add a property for username
        };
    },
    methods: {
        toggleSidebar() {
            this.sidebarOpen = !this.sidebarOpen;
        },
        toggleCollapse() {
            this.collapsed = !this.collapsed;
        },
        logout() {
            localStorage.removeItem('userToken'); // Remove the token
            this.$router.push('/signin'); // Redirect to sign-in page
          },
        setInitialActiveState() {
            this.navigation.forEach(item => {
                item.current = item.href === this.$route.path;
            });
        },
        async fetchProjectTitle(projectId) {
        try {
            const response = await axios.get(`/api/projects/${projectId}`);
            return response.data.title; // Adjust based on your API response structure
        } catch (error) {
            console.error('Error fetching project title:', error);
            return 'Unknown Project';
        }
        },

        async updateBreadcrumbs() {
            const pathArray = this.$route.path.split('/').filter(path => path);
            let breadcrumbPages = [
                { name: 'Studio', href: '', current: false },
                { name: 'Projects', href: '/studio/projects', current: false }
            ];

            // Assuming the project ID is the second segment after '/studio/projects/'
            const projectIdIndex = pathArray.indexOf('projects') + 1;
            if (pathArray[projectIdIndex] && !isNaN(pathArray[projectIdIndex])) {
                const projectTitle = await this.fetchProjectTitle(pathArray[projectIdIndex]);
                breadcrumbPages.push({ name: projectTitle, href: `/studio/projects/${pathArray[projectIdIndex]}`, current: true });
            }

            this.pages = breadcrumbPages;
        },
        async fetchUserData() {
                try {
                    const response = await axios.get('/api/user');
                    this.username = response.data.username; // Update based on your API response structure
                } catch (error) {
                    console.error("Error fetching user data:", error);
                    this.username = 'Guest'; // Fallback username
                }
            },

    },
    watch: {
        '$route.path': function(newPath) {
            this.navigation.forEach(item => {
                item.current = item.href === newPath;
            });
        },
        '$route'() {
            this.updateBreadcrumbs(); // Update breadcrumbs on route change
            this.setInitialActiveState(); // Update navigation state
        }
    },
    mounted() {
        this.setInitialActiveState();
        this.updateBreadcrumbs(); // Set initial breadcrumbs
        this.fetchUserData(); // Fetch user data on component mount
    },
    components: {
        Dialog,
        DialogPanel,
        Menu,
        MenuButton,
        MenuItem,
        MenuItems,
        TransitionChild,
        TransitionRoot,
        Bars3Icon,
        BellIcon,
        CalendarIcon,
        ChartPieIcon,
        Cog6ToothIcon,
        DocumentDuplicateIcon,
        FolderIcon,
        BookOpenIcon,
        HomeIcon,
        MicrophoneIcon,
        UsersIcon,
        XMarkIcon,
        ChevronDownIcon,
        MagnifyingGlassIcon,
        ChevronRightIcon,
        BackwardIcon,
        PlayPauseIcon,
        ForwardIcon,
        ArrowUpOnSquareIcon,
        LightBulbIcon,
        SpeakerWaveIcon,
        ArrowRightCircleIcon,
        PaperAirplaneIcon
    }
}
</script>


